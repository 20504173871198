import React from 'react';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    DialogContent,
    Paper,
    Typography
} from '@mui/material';
import { t } from './Util_format';
import { theme } from './theme';

function Modal_InstitutionsList({ onClose, isOpen, assetType, user_institutions, handleRefreshAssets }) {

    const handleClose = () => {
        onClose();
    };

    const handleAccountClick = (account) => {
        alert(`Selected bank: ${account.user_institution_label} - Not yet developed in this alpha version.`);
    };

    // Styling for the headers and rows
    const headerStyles = {
        background: '#f0f0f0',
        color: '#000',
        fontWeight: 'bold'
    };

    const rowStyles = {
        cursor: 'pointer',
        '&:hover': {
            background: '#f5f5f5'
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Dialog
                open={isOpen}
                onClose={handleClose}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle>{t('list_institutions_title')}</DialogTitle>

                <DialogContent>
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                <TableRow style={headerStyles}>
                                    <TableCell align="center">{t('institution_label')}</TableCell>
                                    <TableCell align="center">{t('institution_status')}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {user_institutions.map((account, index) => (
                                    <TableRow key={index} hover onClick={() => handleAccountClick(account)} style={rowStyles}>
                                        <TableCell align="center">{account.user_institution_label}</TableCell>
                                        <TableCell align="center">{t(account.user_institution_current_status)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Typography sx={{ paddingTop: '20px' }}>
                        {t('click_institution_to_view_or_edit')}
                    </Typography>
                </DialogContent>

                <DialogActions>
                    <Button onClick={handleClose} variant="contained">
                        {t('close')}
                    </Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    );
}

export { Modal_InstitutionsList };
