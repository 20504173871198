import React, { useState, useEffect, useRef } from 'react';
import { TextField, Typography, Button, Link, CircularProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { getCookieValue, setCookieValue } from './App';
import './Unauthenticated.css';
import config from './config/env.json';
import logopng from './pics/logo.png';
import translations from './config/translations.json';
import { t } from './Util_format';

function Login_otp() {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [timer, setTimer] = useState(30);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const inputsRef = useRef([]);
  const submitButtonRef = useRef(null);
  const [isProcessing, setIsProcessing] = useState(false);

  const handleKeyDown = (e, index) => {
    if (e.key === 'Backspace' && !e.target.value) {
      if (index > 0) {
        inputsRef.current[index - 1].focus();
      }
    }
    if (e.key === 'Enter') {
      handleSubmit(); // Call the login function when Enter key is pressed
    }
  };

  useEffect(() => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('firebaseToken='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;
    const loginOtpCompleted = getCookieValue('login_otp_completed');
    const KycCompleted = getCookieValue('kyc_completed');

    if (!cookieValue) {
      navigate('/login');
    } else {
      if (KycCompleted === 'false') {
        navigate('/kyc');
      }
      if (loginOtpCompleted === 'true') {
        navigate("/home");
      }
    }

    if (inputsRef.current[0]) {
      inputsRef.current[0].focus();
    }

  }, []);

  useEffect(() => {
    if (timer > 0) {
      const timeoutId = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(timeoutId);
    }
  }, [timer]);

  const handleChange = (index, value) => {
    if (!/^[0-9]$/.test(value) && value !== "") return;

    setOtp(prevOtp => {
      const newOtp = [...prevOtp];
      newOtp[index] = value;
      return newOtp;
    });

    if (value) {
      if (index === 5) {
        submitButtonRef.current.focus();
      } else if (index < 5) {
        inputsRef.current[index + 1].focus();
      }
    }
  };

  const handleSubmit = async () => {
    setIsProcessing(true);
    setError();
    const otpCode = otp.join("");

    const token = getCookieValue('firebaseToken');

    if (!/^\d{6}$/.test(otpCode)) {
      setError("Per favore inserisci un codice OTP di 6 cifre.");
      setIsProcessing(false);
      return;
    }

    try {
      const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/auth/login-mfa/verify-otp`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: new URLSearchParams({
          otp_code: otpCode,
          otp_type: 'login'
        })
      });

      const data = await response.json();

      if (!response.ok) {
        // console.log(data);
        switch (data.message) {
          case 'OTP has expired':
            setError("Il codice OTP e' scaduto. Per favore richiedi un nuovo codice.");
            break;
          case 'Invalid OTP':
            setError("Il codice OTP inserito non e' valido.");
            break;
          case 'OTP Attempts Exceeded':
            setError("Tentativi OTP esauriti. Per favore fai un nuovo login.");
            navigate('/login?action=clean-cookie&reason=too_many_attempts');
            break;
          case 'Email was not verified':
            navigate('/login?action=clean-cookie&reason=too_many_attempts');
            break;
          default:
            setError(data.message || "An error occurred.");
            break;
        }
        setIsProcessing(false);
        return;
      }
      setCookieValue('login_otp_completed', 'true');
      navigate("/home");

    } catch (error) {
      setError("Network error occurred.");
      setIsProcessing(false);
    }
  };

  const handleResendOtp = async () => {
    setIsProcessing(true);

    setOtp(Array(6).fill(""));

    const token = getCookieValue('firebaseToken');

    try {
      const response = await fetch(`${config.API_DOMAIN_RETAIL}/api/auth/login-mfa/send-verification`, {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          device_type: 'desktop'
        })
      });

      if (!response.ok) {
        throw new Error(`API call failed with status ${response.status}`);
      }

      const apiResult = await response.json();
      // console.log("API result:", apiResult);
      // console.log("Logged in successfully!");

      setTimer(30);

    } catch (error) {
      console.error("Login error:", error);
      setError(error.message);
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div id="page_login_otp">
      <div className="container">
        <div className="unauthenticated_left_container">
          <img src={logopng} alt={t("logo")} className="login_logo_desktop" />
          <div className="overlayImage"></div>
        </div>

        <div className="unauthenticated_right_container">
          <div style={{ width: "100%" }}>
            <img src={logopng} alt={t("logo")} className="login_logo_mobile" />
          </div>
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {t("sms_verification_title")}
          </Typography>
          <Typography variant="body1" gutterBottom>
            {t("we_sent_you_code")}
          </Typography>

          <div className="otpInputs">
            {otp.map((digit, index) => (
              <TextField
                key={index}
                variant="outlined"
                inputProps={{
                  maxLength: 1,
                  style: { textAlign: 'center', width: '2ch' }
                }}
                inputRef={ref => inputsRef.current[index] = ref}
                value={digit}
                onChange={(e) => handleChange(index, e.target.value)}
                onKeyDown={(e) => handleKeyDown(e, index)}
              />
            ))}
          </div>

          {timer > 0 ? (
            <div>
              <div style={{ width: "100%", paddingTop: '10px'}}>{t("resend_sms_in")}</div>
              <div style={{ position: 'relative', width: '50px', height: '50px', width: '100%', marginTop: ' 20px', marginBottom: ' 20px' }}>

                <CircularProgress
                  variant="determinate"
                  value={(100 * timer) / 30}
                  size={50}
                />
                <div style={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontWeight: 'bold'
                }}>
                  {timer}
                </div>
              </div>
            </div>
          ) : (
            <Link
              id="resendSMSLink"
              href="#"
              variant="body2"
              underline="none"
              className="reSendSMS"
              onClick={handleResendOtp}
              disabled={isProcessing}
            >
              {t("resend_sms")}
            </Link>
          )}

          {error &&
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{error}</Typography>
            </div>
          }

          <Button
            ref={submitButtonRef}
            variant="contained"
            color="primary"
            className={`unauthenticated_button ${isProcessing ? "processingButton" : ""}`}
            disabled={isProcessing}
            onClick={handleSubmit}
          >
            {isProcessing ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              t("submit")
            )}
          </Button>

          <Link href="/login?action=clean-cookie" variant="body2" underline="none" className="returnToLogin">
            {t("back_to_login")}
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Login_otp;