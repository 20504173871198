import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Select, Button, MenuItem, InputLabel, FormControl, ListItemIcon, ListItemText, Box, useMediaQuery, ThemeProvider, TextField, styled } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchTrendChartData, get_page_data } from './Util_API_calls';
import { t } from './Util_format';
import config from './config/env.json';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CollectionsIcon from '@mui/icons-material/Collections';
import BarChartIcon from '@mui/icons-material/BarChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HomeIcon from '@mui/icons-material/Home';
import SavingsIcon from '@mui/icons-material/Savings';
import SecurityIcon from '@mui/icons-material/Security';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PieChartIcon from '@mui/icons-material/PieChart';
import { Table_liabilities_all } from './Table_liabilities_all';
import { Table_liabilities_others } from './Table_liabilities_others';
import { Table_liabilities_creditcards } from './Table_liabilities_creditcards';
import { Modal_InstitutionsList } from './Modal_InstitutionsList';
import { Modal_InstitutionCreate } from './Modal_InstitutionCreate';
import Modal_LiabilitiesActions from './Modal_LiabilitiesActions';
import Modal_LiabilitiesSettings from './Modal_LiabilitiesSettings';
import Modal_LiabilityCreate_all from './Modal_LiabilityCreate_all';
import Modal_LiabilityCreate_creditcard from './Modal_LiabilityCreate_creditcard';
import Modal_LiabilityCreate_other from './Modal_LiabilityCreate_other';
import Modal_LiabilityDeleteCloseCreditCard from './Modal_LiabilityDeleteCloseCreditCard';
import Modal_LiabilityDeleteCloseOther from './Modal_LiabilityDeleteCloseOther';
import { PieChart, Pie, Cell, ResponsiveContainer, LabelList } from 'recharts';
import { theme, PIE_COLORS } from './theme';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
Chart.register(...registerables);

const Icons = {
  ListAltIcon,
  AccountBalanceIcon,
  ShowChartIcon,
  BusinessCenterIcon,
  CollectionsIcon,
  BarChartIcon,
  CreditCardIcon,
  CurrencyBitcoinIcon,
  TrendingUpIcon,
  MoreHorizIcon,
  HomeIcon,
  SavingsIcon,
  SecurityIcon,
  StackedLineChartIcon,
  DirectionsCarIcon,
};

export const cellTextStyle = {
  color: 'theme.typography.body1.color', // Use the text color from the theme
};

const iconStyle = {
  color: '#2a9461',
  height: '24px',
  width: '24px'
};

const menuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '48px'
};

const textStyle_small_light = {
  fontWeight: 500,
  color: '#34403A',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

function Page_Liabilities({ onDataChange, ...props }) {
  const [category, setCategory] = React.useState('');
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [liabilities, setLiabilities] = useState([]);
  const [user_institutions, setUserInstitutions] = useState([]);
  const [trading_accounts, setTradingAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const { URLliabilityType } = useParams(); // Grab the URLliabilityType parameter from the URL
  const navigate = useNavigate();
  const [totalPL, setTotalPL] = useState(0);
  const [dailyTotals, setDailyTotals] = useState([]);
  const [PieDataTotal, setPieDataTotal] = useState(0);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [base_currency, setBaseCurrency] = useState('USD'); // Initialize with default currency
  const [show_PL_in, setshow_PL_in] = useState('CURRENCY'); // Initialize with default currency
  const [hoveredPieSliceIndices, setHoveredPieSliceIndices] = useState({});
  const [timeFrame, setTimeFrame] = useState('1M'); // Default to 1 month
  const [isLoadingLiabilities, setIsLoadingLiabilities] = useState(true);
  const [isLoadingTrendChart, setIsLoadingTrendChart] = useState(true);

  const { operation, liabilityID } = useParams();

  useEffect(() => {
    if (operation === 'create' && URLliabilityType === 'creditcards') {
      setSelectedLiability(liabilities.find(liability => liability.user_liability_id === liabilityID));
      setIsLiabilityCreateCreditCardModalOpen(true);
    }
    if (operation === 'delete' && URLliabilityType === 'creditcards') {
      setSelectedLiability(liabilities.find(liability => liability.user_liability_id === liabilityID));
      setIsLiabilityDeleteCreditCardModalOpen(true);
    }
    if (operation === 'create' && URLliabilityType === 'others') {
      setSelectedLiability(liabilities.find(liability => liability.user_liability_id === liabilityID));
      setIsLiabilityCreateOtherModalOpen(true);
    }
    if (operation === 'delete' && URLliabilityType === 'others') {
      setSelectedLiability(liabilities.find(liability => liability.user_liability_id === liabilityID));
      setIsLiabilityDeleteOtherModalOpen(true);
    }

  }, [operation, URLliabilityType, liabilities]);

  useEffect(() => {
    // set liability.dynamic_current_quantity to 1 if not set 
    if (liabilities) {
      liabilities.forEach((liability) => {
        if (!liability.dynamic_current_quantity) {
          liability.dynamic_current_quantity = 1;
        }
      });
    }

    let initialPL = null;
    const total_PL = liabilities.reduce((acc, liability) => {
      if (liability.PL_unit && liability.PL_unit[base_currency]) {
        return (acc === null ? 0 : acc) + liability.PL_unit[base_currency] * liability.dynamic_current_quantity;
      }
      return acc;
    }, initialPL);
    setTotalPL(total_PL === null ? null : total_PL);

    const total_value = liabilities.reduce((acc, liability) => {
      if (liability.product_last_value && liability.product_last_value[base_currency]) {
        return acc + liability.product_last_value[base_currency] * liability.dynamic_current_quantity;
      }
      if (liability.dynamic_balance && liability.dynamic_balance[base_currency]) {
        return acc + liability.dynamic_balance[base_currency];
      }
      if (liability.last_value && liability.last_value[base_currency]) {
        return acc + liability.last_value[base_currency];
      }
      return acc;
    }, 0);

    setPieDataTotal(total_value);

  }, [liabilities, base_currency]); // Make sure to include settings.base_currency in dependency array

  // console.log(config.LIABILITY_CATEGORIES);
  useEffect(() => {
    // Find the corresponding category title from the config
    const selectedCategory = config.LIABILITY_CATEGORIES.find(c => c.user_liability_type === URLliabilityType);
    if (selectedCategory) {
      setCategory(selectedCategory.user_liability_type);
    }
  }, [URLliabilityType]);

  const handlePageChange = (newPage) => {
    setIsLoading(true);
    setCurrentPage(newPage);
  };

  const handleChange = (event) => {
    setIsLoading(true);
    const selectedCategoryTitle = event.target.value;
    const selectedCategory = config.LIABILITY_CATEGORIES.find(c => c.user_liability_type === selectedCategoryTitle);
    if (selectedCategory) {
      setCategory(selectedCategory.user_liability_type); // Set the user_liability_type
      navigate(`/liabilities/${selectedCategory.user_liability_type}`);
    }
  };

  const findCategory = (selected) => {
    return config.LIABILITY_CATEGORIES.find(item => item.user_liability_type === selected);
  };

  const accountIcon = (type) => {
    const category = config.LIABILITY_CATEGORIES.find(c => c.user_liability_type === type);
    if (category && category.icon) {
      const IconComponent = Icons[category.icon]; // Assuming you have an object mapping string to icon components
      return <IconComponent style={iconStyle} />;
    }
    return <ListIcon style={iconStyle} />;
  };

  // Fetch Liabilities List
  const fetch_liabilities_list = async () => {
    setIsLoadingLiabilities(true);
    const selectedCategory = config.LIABILITY_CATEGORIES.find(acc => acc.user_liability_type === URLliabilityType);
    if (!selectedCategory) {
      console.error("No selected category found for category data fetch (" + selectedCategory + ")");
      return;
    }
    setIsLoading(true);
    try {
      setLiabilities([]);
      const data = await get_page_data('liabilities', selectedCategory.user_liability_type);
      if (data && data.liabilities) {
        setBaseCurrency(data.settings.base_currency); // Update base_currency based on API response

        setLiabilities(data.liabilities);
        setUserInstitutions(data.user_institutions);
        // console.log('user_institutions', data);
        setTradingAccounts(data.trading_accounts);
      } else {
        // console.log("No liabilities found for category: ", category);
        setLiabilities([]);
      }
    } catch (error) {
      console.error('Error fetching liabilities list:', error);
      setLiabilities([]);
    } finally {
      setIsLoading(false);
    }
    setIsLoadingLiabilities(false);
  };

  // Fetch Trend Chart Data
  const fetch_trend_chart_data = async () => {
    setIsLoadingTrendChart(true);

    const selectedCategory = config.LIABILITY_CATEGORIES.find(acc => acc.user_liability_type === URLliabilityType);

    // Check if the selectedCategory is not found or user_liability_type is empty
    if (!selectedCategory || !selectedCategory.user_liability_type) {
      console.error("No valid category found for trend chart data fetch.");
      setIsLoadingTrendChart(false);
      return;
    }

    if (!selectedCategory) {
      console.error("No selected category found for trend chart data fetch (" + selectedCategory + ")");
      return;
    }
    try {
      const data = await fetchTrendChartData(selectedCategory.user_liability_type, 'this_year', base_currency);
      if (data && data.daily_totals) {
        setDailyTotals(data.daily_totals);
        // console.log(data.daily_totals);
      } else {
        // console.log("No trend data found for category: ", category);
      }
    } catch (error) {
      console.error('Error fetching trend data:', error);
    }
    setIsLoadingTrendChart(false);
  };

  // useEffect for fetching liabilities list
  useEffect(() => {
    if (category) {
      fetch_liabilities_list();
    }
  }, [category, URLliabilityType]); // Include URLliabilityType in the dependency array

  // useEffect for fetching trend chart data
  useEffect(() => {
    if (base_currency && category) {
      fetch_trend_chart_data();
    }
  }, [base_currency, category]);

  const loss_color = {
    color: '#c0392b',
  };

  const gain_color = {
    color: '#2a9461',
  };

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [isLiabilityCreateCreditCardModalOpen, setIsLiabilityCreateCreditCardModalOpen] = useState(false);
  const [isLiabilityCreateOtherModalOpen, setIsLiabilityCreateOtherModalOpen] = useState(false);
  const [isLiabilityDeleteCreditCardModalOpen, setIsLiabilityDeleteCreditCardModalOpen] = useState(false);
  const [isLiabilityDeleteOtherModalOpen, setIsLiabilityDeleteOtherModalOpen] = useState(false);
  const [isInstitutionsListModalOpen, setIsInstitutionsListModalOpen] = useState(false);
  const [isInstitutionsCreateModalOpen, setIsInstitutionsCreateModalOpen] = useState(false);
  const [isLiabilityCreateAllModalOpen, setIsLiabilityCreateAllModalOpen] = useState(false);

  const handleOpenSettingsModal = () => {
    setIsSettingsModalOpen(true);
  };

  const handleOpenActionsModal = (liability) => {
    setSelectedLiability(liability);
    navigate(`/liabilities/${URLliabilityType}/actions/${liability.user_liability_id}`);
    setIsActionsModalOpen(true);
  };

  const handleOpenLiabilityCreateCreditCardModal = () => {
    setIsLiabilityCreateCreditCardModalOpen(true);
    navigate(`/liabilities/${URLliabilityType}/create`);
  };

  const handleOpenLiabilityCreateOtherModal = () => {
    setIsLiabilityCreateOtherModalOpen(true);
    navigate(`/liabilities/${URLliabilityType}/create`);
  };

  const handleOpenInstitutionCreateModal = () => {
    setIsInstitutionsCreateModalOpen(true);
  }

  const handleOpenLiabilityCreateAllModal = () => {
    navigate(`/liabilities/${URLliabilityType}/create`);
    setIsLiabilityCreateAllModalOpen(true);
  }

  const handleOpenInstitutionsListModal = () => {
    setIsInstitutionsListModalOpen(true);
  }

  const handleCloseLiabilityCreateCreditCardModal = () => {
    setIsLiabilityCreateCreditCardModalOpen(false);
    navigate(`/liabilities/${URLliabilityType}`);
  };

  const handleCloseLiabilityCreateOtherModal = () => {
    setIsLiabilityCreateOtherModalOpen(false);
    navigate(`/liabilities/${URLliabilityType}`);
  };

  const handleCloseDeleteLiabilityCreditCardModal = () => {
    setIsLiabilityDeleteCreditCardModalOpen(false);
    navigate(`/liabilities/${URLliabilityType}`);
  }

  const handleCloseDeleteLiabilityOtherModal = () => {
    setIsLiabilityDeleteOtherModalOpen(false);
    navigate(`/liabilities/${URLliabilityType}`);
  }

  const handleCloseSettingsModal = () => {
    setIsSettingsModalOpen(false);
    navigate(`/liabilities/${URLliabilityType}`);
  };

  const [selectedLiability, setSelectedLiability] = useState(null);

  const handleCloseActionsModal = () => {
    setIsActionsModalOpen(false);
    // navigate(`/liabilities/${URLliabilityType}`);
  };

  const handleCloseInstitutionsListModal = () => {
    setIsInstitutionsListModalOpen(false);
  };

  const handleCloseInstitutionsCreateModal = () => {
    setIsInstitutionsCreateModalOpen(false);
  };

  const handleCloseLiabilityCreatAllModal = () => {
    setIsLiabilityCreateAllModalOpen(false);
  }

  const renderTableForCategory = (URLliabilityType) => {
    switch (URLliabilityType) {
      case "all":
        let liabilities_aggregated = [];
        let liabilityTypeIndexMap = {};
        liabilities.forEach(liability => {
          // Check if this liability type has been encountered before
          if (!(liability.user_liability_type in liabilityTypeIndexMap)) {
            // Add a new object for this liability type
            liabilityTypeIndexMap[liability.user_liability_type] = liabilities_aggregated.length;
            liabilities_aggregated.push({
              user_liability_type: liability.user_liability_type,
              last_value: {},
              PL_unit: {}
            });
          }

          let index = liabilityTypeIndexMap[liability.user_liability_type];

          // Aggregate last_value for each currency
          for (const currency in liability.last_value) {
            if (!(currency in liabilities_aggregated[index].last_value)) {
              liabilities_aggregated[index].last_value[currency] = 0;
            }
            liabilities_aggregated[index].last_value[currency] += liability.last_value[currency];
          }

          // Aggregate PL_unit for each currency
          for (const currency in liability.PL_unit) {
            if (!(currency in liabilities_aggregated[index].PL_unit)) {
              liabilities_aggregated[index].PL_unit[currency] = 0;
            }
            liabilities_aggregated[index].PL_unit[currency] += liability.PL_unit[currency];
          }
        });
        return (
          <Table_liabilities_all
            key={searchTerm}
            liabilities={liabilities_aggregated}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLliabilityType={URLliabilityType}
          />
        );
      case 'creditcards':
        return (
          <Table_liabilities_creditcards
            key={searchTerm}
            liabilities={liabilities}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLliabilityType={URLliabilityType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'others':
        return (
          <Table_liabilities_others
            key={searchTerm}
            liabilities={liabilities}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLliabilityType={URLliabilityType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      default:
        return <div>Unsupported liability type: {category}</div>;
    }
  };

  const onPieEnter = (pieInfoToShow, index) => {
    setHoveredPieSliceIndices(prev => ({ ...prev, [pieInfoToShow]: index }));
  };

  const onPieLeave = (pieInfoToShow) => {
    setHoveredPieSliceIndices(prev => ({ ...prev, [pieInfoToShow]: -1 }));
  };

  const renderPiesForCategory = (URLliabilityType, PieInfoToShow) => {

    const BuildPieData = () => {
      let liabilities_aggregated = [];
      let liabilityTypeIndexMap = {};

      switch (PieInfoToShow) {
        case 'value':
          switch (URLliabilityType) {
            case "all":
              liabilities.forEach(liability => {

                if (liability.user_liability_type === null || liability.last_value === undefined || !(base_currency in liability.last_value)) {
                  return; // Skip this iteration
                }
                // Check if the user_liability_type is already in the index map
                if (!(liability.user_liability_type in liabilityTypeIndexMap)) {
                  // If not, add it to the index map and create a new entry in liabilities_aggregated
                  liabilityTypeIndexMap[liability.user_liability_type] = liabilities_aggregated.length;
                  liabilities_aggregated.push({
                    pie_label: liability.user_liability_type,
                    pie_percentage: liability.last_value[base_currency],
                  });
                } else {
                  // If it already exists, update the existing entry in liabilities_aggregated
                  let index = liabilityTypeIndexMap[liability.user_liability_type];
                  liabilities_aggregated[index].pie_percentage += liability.last_value[base_currency];
                }
              });
              break;
            case 'creditcards':
              // Copying last_value and PL_unit without aggregating by liability type
              liabilities.forEach(liability => {
                if (!(liability.user_liability_label in liabilityTypeIndexMap)) {
                  liabilityTypeIndexMap[liability.user_liability_label] = liabilities_aggregated.length;
                  const balance = liability.dynamic_balance && liability.dynamic_balance[base_currency] ? liability.dynamic_balance[base_currency] : 0;
                  liabilities_aggregated.push({
                    pie_label: liability.user_liability_label,
                    pie_percentage: balance,
                  });
                }
                let index = liabilityTypeIndexMap[liability.user_liability_label]; // Note: Changed from liability.pie_label to liability.user_liability_label
              });
              break;
          }
          break;
        case 'sector':
          switch (URLliabilityType) {
            case 'creditcards':
              liabilities.forEach(liability => {
                // Check if the product_sector is already in the index map
                if (!(liability.product_sector in liabilityTypeIndexMap)) {
                  // If not, add it to the index map and create a new entry in liabilities_aggregated
                  liabilityTypeIndexMap[liability.product_sector] = liabilities_aggregated.length;
                  liabilities_aggregated.push({
                    pie_label: liability.product_sector,
                    pie_percentage: liability.product_last_value[base_currency],
                  });
                } else {
                  // If it already exists, update the existing entry in liabilities_aggregated
                  let index = liabilityTypeIndexMap[liability.product_sector];
                  liabilities_aggregated[index].pie_percentage += liability.product_last_value[base_currency] * liability.dynamic_current_quantity;
                }
              });
              break;
          }
          break;
        case 'PL_up':
          switch (URLliabilityType) {
            case 'creditcards':
              // Copying last_value and PL_unit without aggregating by liability type
              liabilities.forEach(liability => {
                if (!(liability.user_liability_label in liabilityTypeIndexMap)) {
                  if (liability.PL_unit[base_currency] > 0) {
                    liabilityTypeIndexMap[liability.user_liability_label] = liabilities_aggregated.length;
                    liabilities_aggregated.push({
                      pie_label: liability.user_liability_label,
                      pie_percentage: liability.PL_unit[base_currency] * liability.dynamic_current_quantity,
                    });
                  }
                }
                let index = liabilityTypeIndexMap[liability.pie_label];
              });
              break;
          }
          break;
        case 'PL_down':
          switch (URLliabilityType) {
            case 'creditcards':
              // Copying last_value and PL_unit without aggregating by liability type
              liabilities.forEach(liability => {
                if (!(liability.user_liability_label in liabilityTypeIndexMap)) {
                  if (liability.PL_unit[base_currency] < 0) {
                    liabilityTypeIndexMap[liability.user_liability_label] = liabilities_aggregated.length;
                    liabilities_aggregated.push({
                      pie_label: liability.user_liability_label,
                      pie_percentage: liability.PL_unit[base_currency] * liability.dynamic_current_quantity,
                    });
                  }
                }
                let index = liabilityTypeIndexMap[liability.pie_label];
              });
              break;
          }
          break;
        case 'currency':
          switch (URLliabilityType) {
            case 'creditcards':
              liabilities.forEach(liability => {
                // Check if the user_liability_type is already in the index map
                if (!(liability.user_liability_product_currency in liabilityTypeIndexMap)) {
                  // If not, add it to the index map and create a new entry in liabilities_aggregated
                  liabilityTypeIndexMap[liability.user_liability_product_currency] = liabilities_aggregated.length;
                  liabilities_aggregated.push({
                    pie_label: liability.user_liability_product_currency,
                    pie_percentage: liability.product_last_value[base_currency],
                  });
                } else {
                  // If it already exists, update the existing entry in liabilities_aggregated
                  let index = liabilityTypeIndexMap[liability.user_liability_product_currency];
                  liabilities_aggregated[index].pie_percentage += liability.product_last_value[base_currency] * liability.dynamic_current_quantity;
                }
              });
              break;
          }
          break;
        case 'country':
          switch (URLliabilityType) {
            case 'creditcards':
              liabilities.forEach(liability => {
                // Check if the user_liability_type is already in the index map
                if (!(liability.product_country in liabilityTypeIndexMap)) {
                  // If not, add it to the index map and create a new entry in liabilities_aggregated
                  liabilityTypeIndexMap[liability.product_country] = liabilities_aggregated.length;
                  liabilities_aggregated.push({
                    pie_label: liability.product_country,
                    pie_percentage: liability.product_last_value[base_currency],
                  });
                } else {
                  // If it already exists, update the existing entry in liabilities_aggregated
                  let index = liabilityTypeIndexMap[liability.product_country];
                  liabilities_aggregated[index].pie_percentage += liability.product_last_value[base_currency] * liability.dynamic_current_quantity;
                }
              });
              break;
          }
          break;
        case 'brokerage_account':
          switch (URLliabilityType) {
            case 'creditcards':
              liabilities.forEach(liability => {
                // Check if the user_liability_type is already in the index map
                if (!(liability.user_ta_label in liabilityTypeIndexMap)) {
                  // If not, add it to the index map and create a new entry in liabilities_aggregated
                  liabilityTypeIndexMap[liability.user_ta_label] = liabilities_aggregated.length;
                  liabilities_aggregated.push({
                    pie_label: liability.user_ta_label,
                    pie_percentage: liability.product_last_value[base_currency],
                  });
                } else {
                  // If it already exists, update the existing entry in liabilities_aggregated
                  let index = liabilityTypeIndexMap[liability.user_ta_label];
                  liabilities_aggregated[index].pie_percentage += liability.product_last_value[base_currency] * liability.dynamic_current_quantity;
                }
              });
              break;
          }
          break;
      }
      let PieDataTotal = liabilities_aggregated.reduce((sum, liability) => sum + liability.pie_percentage, 0);
      liabilities_aggregated.forEach(liability => {
        liability.pie_percentage = (liability.pie_percentage / PieDataTotal) * 100;
      });
      return liabilities_aggregated.sort((a, b) => b.pie_percentage - a.pie_percentage);
    };

    switch (URLliabilityType) {
      case "all":
      case "creditcards":
        const pieData = BuildPieData();
        return (
          <>
            <ResponsiveContainer width="100%" height={300}>
              <PieChart>
                <Pie
                  data={pieData}
                  dataKey="pie_percentage"
                  nameKey="pie_label"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#8884d8"
                  labelLine={false}
                  label={({ pie_label, pie_percentage, index }) => {
                    // Check if the pie slice is hovered for this specific chart
                    if (hoveredPieSliceIndices[PieInfoToShow] === index) {
                      const formattedValue = `${pie_percentage.toFixed(2)}%`;
                      return `${formattedValue} | ${pie_label}`;
                    }
                    return null;
                  }}
                  onMouseEnter={(e, index) => onPieEnter(PieInfoToShow, index)}
                  onMouseLeave={() => onPieLeave(PieInfoToShow)}
                >
                  {
                    pieData.map((entry, index) => (
                      <Cell key={`cell-${PieInfoToShow}-${entry.pie_label}-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                    ))
                  }
                </Pie>
              </PieChart>

            </ResponsiveContainer>
          </>
        );
      default:
        return <div>Unsupported liability type: {URLliabilityType}</div>;
    }

  };


  const chartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Value of my liabilities',
        data: dailyTotals,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      },
      // You can add more datasets here
    ],
  };

  const ChartOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    animation: {
      duration: 0 // No animation
    },
    hover: {
      animationDuration: 0 // No animation on hover
    },
    responsiveAnimationDuration: 0 // No animation on window resizing
  };


  const updateChartData = (selectedTimeFrame) => {
    // Logic to update data based on selectedTimeFrame
    // This might involve fetching new data or filtering your dataset
    // For example:
    // if (selectedTimeFrame === '1M') { ... }
    // ...
  };

  let carouselItems = [];

  carouselItems.push({
    title: 'value',
    renderFunc: () => renderPiesForCategory(URLliabilityType, 'value'),
  });

  if (URLliabilityType === 'creditcards') {
    carouselItems.push({
      title: 'sector',
      renderFunc: () => renderPiesForCategory(URLliabilityType, 'sector'),
    });
  }

  if (URLliabilityType === 'creditcards') {
    carouselItems.push({
      title: 'currency',
      renderFunc: () => renderPiesForCategory(URLliabilityType, 'currency'),
    });
  }

  if (URLliabilityType === 'creditcards') {
    carouselItems.push({
      title: 'country',
      renderFunc: () => renderPiesForCategory(URLliabilityType, 'country'),
    });
  }

  if (URLliabilityType === 'creditcards') {
    carouselItems.push({
      title: 'PL_up',
      renderFunc: () => renderPiesForCategory(URLliabilityType, 'PL_up'),
    });
  }

  if (URLliabilityType === 'creditcards') {
    carouselItems.push({
      title: 'PL_down',
      renderFunc: () => renderPiesForCategory(URLliabilityType, 'PL_down'),
    });
  }

  if (URLliabilityType === 'creditcards') {
    carouselItems.push({
      title: 'brokerage_account',
      renderFunc: () => renderPiesForCategory(URLliabilityType, 'brokerage_account'),
    });
  }

  const [sortedCategories, setSortedCategories] = useState([]);
  useEffect(() => {

    const firstItem = config.LIABILITY_CATEGORIES[0];
    const lastItem = config.LIABILITY_CATEGORIES[config.LIABILITY_CATEGORIES.length - 1];

    const middleItems = config.LIABILITY_CATEGORIES.slice(1, -1).sort((a, b) => {
      const labelA = t('categories_liabilities_' + a.user_liability_type).toLowerCase();
      const labelB = t('categories_liabilities_' + b.user_liability_type).toLowerCase();
      return labelA.localeCompare(labelB);
    });

    setSortedCategories([firstItem, ...middleItems, lastItem]);

  }, [t, config.LIABILITY_CATEGORIES]); // Add other dependencies if needed

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>
        {isSettingsModalOpen && (
          <Modal_LiabilitiesSettings
            isOpen={isSettingsModalOpen}
            onClose={handleCloseSettingsModal}
            setBaseCurrency={setBaseCurrency}
            setshow_PL_in={setshow_PL_in}
            currentCurrency={base_currency}
            currentshow_PL_in={show_PL_in}
          />
        )}
        {isActionsModalOpen && (
          <Modal_LiabilitiesActions
            isOpen={isActionsModalOpen}
            onClose={handleCloseActionsModal}
            liability={selectedLiability}
            liabilityType={URLliabilityType}
            handleRefreshLiabilities={fetch_liabilities_list}
            trading_accounts={trading_accounts}
          />
        )}
        {isInstitutionsListModalOpen && (
          <Modal_InstitutionsList
            isOpen={isInstitutionsListModalOpen}
            onClose={handleCloseInstitutionsListModal}
            liabilityType={URLliabilityType}
            handleRefreshLiabilities={fetch_liabilities_list}
            user_institutions={user_institutions}
          />
        )}
        {isInstitutionsCreateModalOpen && (
          <Modal_InstitutionCreate
            isOpen={isInstitutionsCreateModalOpen}
            onClose={handleCloseInstitutionsCreateModal}
            liabilityType={URLliabilityType}
            handleRefreshLiabilities={fetch_liabilities_list}
            user_institutions={user_institutions}
          />
        )}
        {isLiabilityCreateAllModalOpen && (
          <Modal_LiabilityCreate_all
            isOpen={isLiabilityCreateAllModalOpen}
            onClose={handleCloseLiabilityCreatAllModal}
            liabilityType={URLliabilityType}
            handleRefreshLiabilities={fetch_liabilities_list}
            user_institutions={user_institutions}
          />
        )}
        {isLiabilityCreateCreditCardModalOpen && (
          <Modal_LiabilityCreate_creditcard
            isOpen={isLiabilityCreateCreditCardModalOpen}
            onClose={handleCloseLiabilityCreateCreditCardModal}
            liabilityType={URLliabilityType}
            handleRefreshLiabilities={fetch_liabilities_list}
            user_institutions={user_institutions}
          />
        )}
        {isLiabilityCreateOtherModalOpen && (
          <Modal_LiabilityCreate_other
            isOpen={isLiabilityCreateOtherModalOpen}
            onClose={handleCloseLiabilityCreateOtherModal}
            liabilityType={URLliabilityType}
            handleRefreshLiabilities={fetch_liabilities_list}
            user_institutions={user_institutions}
          />
        )}
        {isLiabilityDeleteCreditCardModalOpen && (
          <Modal_LiabilityDeleteCloseCreditCard
            isOpen={isLiabilityDeleteCreditCardModalOpen}
            onClose={handleCloseDeleteLiabilityCreditCardModal}
            liability={selectedLiability}
            handleRefreshLiabilities={fetch_liabilities_list}
          />
        )}
        {isLiabilityDeleteOtherModalOpen && (
          <Modal_LiabilityDeleteCloseOther
            isOpen={isLiabilityDeleteOtherModalOpen}
            onClose={handleCloseDeleteLiabilityOtherModal}
            liability={selectedLiability}
            handleRefreshLiabilities={fetch_liabilities_list}
          />
        )}
        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <CardContent>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <FormControl style={{ marginRight: '10px', width: '250px' }}>
                    <InputLabel id="account-select-label" className="liabilities_input-label">
                      {t('select_liability_type')}
                    </InputLabel>
                    <Select
                      labelId="account-select-label"
                      id="account-select"
                      value={category}
                      label={t('select_liability_type')}
                      onChange={handleChange}
                      color="primary"
                      IconComponent={ListIcon}
                      renderValue={(selected) => (
                        <Box display="flex" alignItems="center">
                          {findCategory(selected) ? accountIcon(findCategory(selected).user_liability_type) : null}
                          <Typography variant="body2" noWrap style={{ ...textStyle_small_light, marginLeft: '8px' }}>
                            {t('categories_liabilities_' + selected)}
                          </Typography>
                        </Box>
                      )}
                    >
                      {sortedCategories.map((item) => (
                        <MenuItem key={item.user_liability_type} value={item.user_liability_type} style={menuItemStyle}>
                          <ListItemIcon>{accountIcon(item.user_liability_type)}</ListItemIcon>
                          <ListItemText primary={t('categories_liabilities_' + item.user_liability_type)} />
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                  <TextField
                    style={{ flexGrow: 2, marginRight: '10px' }}
                    name="search_filter_unique"
                    label={t('search')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoComplete="no-autofill"
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* ... other components like TextField */}

                    <Box sx={{
                      border: '1px solid #c4c4c4', // adjust this color to match your inputs border color
                      borderRadius: '4px', // adjust this value to match your theme's border radius
                      paddingLeft: '30px', // adjust this padding to match the TextField's padding
                      paddingRight: '30px', // adjust this padding to match the TextField's padding
                      marginRight: '8px', // adjust margin as needed for spacing
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      height: '56px', // adjust this value to match the height of your TextFields
                    }}>

                      <span style={{
                        position: 'absolute',
                        top: '-9px', // adjust this value to correctly position the label as per your design
                        left: '10px', // adjust this value to correctly position the label as per your design
                        background: '#fff', // this should match the background color of your container
                        padding: '0 4px',
                        fontSize: '12px', // adjust this value to match the label font size of your TextFields
                        color: 'rgba(0, 0, 0, 0.6)' // this should match the border color or the color you want the label text to be
                      }}>
                        {t('operations')}
                      </span>
                      {URLliabilityType === 'all' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenLiabilityCreateAllModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('record_new_liability_button_' + URLliabilityType)}
                        </Button>
                      )}
                      {URLliabilityType === 'creditcards' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenInstitutionCreateModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ marginRight: '8px', textTransform: 'capitalize' }}
                        >
                          {t('create_institution')}
                        </Button>
                      )}
                      {URLliabilityType === 'creditcards' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenInstitutionsListModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ marginRight: '8px', textTransform: 'capitalize' }}
                        >
                          {t('list_institutions')}
                        </Button>
                      )}
                      {URLliabilityType === 'others' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenLiabilityCreateOtherModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('record_new_liability_button_' + URLliabilityType)}
                        </Button>
                      )}
                      {URLliabilityType === 'creditcards' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenLiabilityCreateCreditCardModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('record_new_liability_button_' + URLliabilityType)}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <CardContent>
                {!isLoading &&
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom>
                        {t('list_of_liabilities')}
                      </Typography>

                      <SettingsIcon onClick={handleOpenSettingsModal} style={{ cursor: 'pointer' }} />
                    </div>
                    <Typography variant="body1" style={{ ...textStyle_small_light }}>
                      {t('total_value')}:&nbsp;
                      <span style={{
                        ...cellTextStyle,
                        ...(PieDataTotal < 0 ? loss_color : gain_color),
                      }}>
                        {PieDataTotal.toLocaleString('en-AU', { style: 'currency', currency: base_currency })}
                      </span>
                      {totalPL !== null && (
                        <>
                          <span style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            ...cellTextStyle
                          }}>|
                          </span>
                          {t('total_PL')}:&nbsp;
                          <span style={{
                            ...cellTextStyle,
                            ...(totalPL < 0 ? loss_color : gain_color),
                          }}>
                            {totalPL.toLocaleString('en-AU', { style: 'currency', currency: base_currency })}
                          </span>
                          <span style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            ...cellTextStyle
                          }}>|
                          </span>
                          {t('average_lot_size')}:&nbsp;
                          <span style={{
                            ...cellTextStyle,
                          }}>
                            {(PieDataTotal / liabilities.length).toLocaleString('en-AU', { style: 'currency', currency: base_currency })}
                          </span>
                        </>
                      )}
                    </Typography>
                  </>
                }
                {isLoadingLiabilities ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                    <CircularProgress />
                  </Box>
                ) : (renderTableForCategory(URLliabilityType, 'value'))}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} style={{ marginBottom: '10px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Trend Chart
                </Typography>
                <FormControl fullWidth style={{ marginBottom: '20px' }}>
                  <InputLabel id="timeframe-select-label">Time Frame</InputLabel>
                  <Select
                    labelId="timeframe-select-label"
                    id="timeframe-select"
                    value={timeFrame}
                    label="Time Frame"
                    onChange={(e) => {
                      setTimeFrame(e.target.value);
                      updateChartData(e.target.value);
                    }}
                  >
                    <MenuItem value="1M">1 Month</MenuItem>
                    <MenuItem value="1Q">1 Quarter</MenuItem>
                    <MenuItem value="1Y">1 Year</MenuItem>
                    <MenuItem value="ALL">All</MenuItem>
                  </Select>
                </FormControl>
                {isLoadingTrendChart ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                    <CircularProgress />
                  </Box>
                ) : (
                  <Line data={chartData} options={ChartOptions} />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent>
                <Carousel
                // Add any additional props or class names here
                >
                  {carouselItems.map((item, index) => (
                    <div key={`carousel-item-${index}`}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" gutterBottom>
                          {t(item.title + '_pie_title')}
                        </Typography>
                        <PieChartIcon onClick={handleOpenSettingsModal} style={{ cursor: 'pointer' }} />
                      </div>
                      {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                          <CircularProgress />
                        </Box>
                      ) : (
                        item.renderFunc()
                      )}
                    </div>
                  ))}
                </Carousel>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginBottom: '10px' }}>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default Page_Liabilities;
