import React, { useState } from 'react';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { t } from './Util_format';
import { cellTextStyle } from './Page_Assets';


export function Table_assets_bankaccounts_credits_deposits({ URLassetType, assets, onPageChange, searchTerm, category, base_currency, handleOpenActionsModal }) {
  const [sortConfig, setSortConfig] = useState({ key: 'user_asset_label', direction: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);


  const requestSort = (key, type) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction, type });
  };

  const TableSort = (a, b, sortConfig) => {
    const { key, direction, type } = sortConfig;
    const resolvePath = (object, path, defaultValue) => {
      return path.split('.').reduce((o, p) => o ? o[p] : defaultValue, object);
    };

    let valA = resolvePath(a, key, null);
    let valB = resolvePath(b, key, null);

    // Normalize values for sorting
    switch (type) {
      case 'currency':
        valA = valA ? parseCurrencyValue(valA) : 0;
        valB = valB ? parseCurrencyValue(valB) : 0;
        break;
      case 'number':
        valA = parseNumberValue(valA);
        valB = parseNumberValue(valB);
        break;
      case 'string':
        valA = valA ? valA.toLowerCase() : '';
        valB = valB ? valB.toLowerCase() : '';
        break;
    }

    // Sort logic
    if (valA < valB) {
      return direction === 'asc' ? -1 : 1;
    }
    if (valA > valB) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  };

  function parseCurrencyValue(value) {
    const num = Number(value.replace(/[^0-9.-]+/g, ""));
    return isNaN(num) || !isFinite(num) ? Infinity : num;
  }

  function parseNumberValue(value) {
    const num = parseFloat(value);
    return isNaN(num) || !isFinite(num) ? Infinity : num;
  }


  const renderTableSort = (key, label, type) => (
    <TableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key, type)}
    >
      {t(label)}
    </TableSortLabel>
  );

  if (!category) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('please_select_the_asset_category')}
      </Typography>
    );
  } else if (!Array.isArray(assets) || assets.length === 0) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('no_assets_to_show')}
      </Typography>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedAssets = [...assets].sort((a, b) => {
    return TableSort(a, b, sortConfig);
  });

  const filteredAssets = sortedAssets.filter(asset => {
    // prevent error switching between shares and bank accounts
    if (!asset.dynamic_balance) {
      return false;
    }
    const combinedFields = [
      asset.user_asset_label,
      asset.user_institution_label,
    ].filter(Boolean).join(' ').toLowerCase(); // Filter out any undefined or null values and join them into a single string
    asset.table_dynamic_balance_original = parseFloat(asset.dynamic_balance[asset.user_asset_currency]).toLocaleString('en-US', { style: 'currency', currency: asset.user_asset_currency });
    asset.table_dynamic_balance_my_currency = parseFloat(asset.dynamic_balance[base_currency]).toLocaleString('en-US', { style: 'currency', currency: base_currency });
    return combinedFields.includes(searchTerm.toLowerCase());
  });

  const displayedAssets = filteredAssets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const evenRowStyle = {
    backgroundColor: '#F0F0F0',
  };

  const loss_color = {
    color: '#c0392b',
  };

  const gain_color = {
    color: '#2a9461',
  };

  return (
    <div>
      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell data-key='user_asset_label' data-type='string'>
              {renderTableSort('user_asset_label', t('label_' + URLassetType), 'string')}
            </TableCell>
            {URLassetType === 'bankaccounts' && (
              <TableCell data-key='user_institution_label' data-type='string' style={{}}>
                {renderTableSort('user_institution_label', t('institution_label'), 'string')}
              </TableCell>
            )}
            <TableCell data-key='table_dynamic_balance_original' data-type='currency' style={{ textAlign: 'right', width: '150px' }}>
              {renderTableSort('table_dynamic_balance_original', t('account_balance'), 'currency')}
            </TableCell>
            <TableCell data-key='table_dynamic_balance_my_currency' data-type='currency' style={{ textAlign: 'right', width: '150px' }}>
              {renderTableSort('table_dynamic_balance_my_currency', t('account_balance_in_my_currency'), 'currency')}
            </TableCell>
            {/* Add additional headers as needed */}
          </TableRow>
        </TableHead>

        <TableBody>
          {displayedAssets.map((asset) => (
            <TableRow key={asset.user_asset_id} onClick={() => handleOpenActionsModal(asset)} style={{ cursor: 'pointer' }}>
              <TableCell style={{ textAlign: 'left', ...cellTextStyle }}>{asset.user_asset_label}</TableCell>
              {URLassetType === 'bankaccounts' && (
                <TableCell style={{ textAlign: 'left', ...cellTextStyle }}>{asset.user_institution_label}</TableCell>
              )}
              <TableCell style={{ textAlign: 'left', ...cellTextStyle, paddingRight: '40px', textAlign: 'right', width: '150px' }}
              >
                {asset.table_dynamic_balance_original}
              </TableCell>
              <TableCell style={{ textAlign: 'left', ...cellTextStyle, paddingRight: '40px', textAlign: 'right', width: '150px' }}>
                {asset.table_dynamic_balance_my_currency}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        style={{ marginTop: '20px' }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredAssets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='table_bottom'
      />
    </div>
  );
}
