import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Autocomplete, TextField, Dialog, DialogActions, Button, CircularProgress, DialogTitle, ThemeProvider, ListItem, ListItemText, FormControl, Typography, } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { t } from './Util_format';
import { theme } from './theme';
import { NumericFormat } from 'react-number-format';
import { fetchBuyAssetsSharesEtfs, fetchFindMonitoredProduct } from './Util_API_calls';

function Modal_AssetBuy_shares_etfs({ onClose, isOpen, assetType, trading_accounts, handleRefreshAssets, asset, }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedTa, setSelectedTa] = useState(null);
  const [purchaseDate, setPurchaseDate] = useState(new Date());
  const [productCode, setProductCode] = useState('');
  const [purchaseCurrency, setPurchaseCurrency] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [filter, setFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const debounceRef = useRef(null);
  const [number_of_items, setNumberOfItems] = useState('');
  const [price_paid_per_item, setPricePaidPerItem] = useState('');
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [selectedShare, setSelectedProduct] = useState(null);
  const navigate = useNavigate();

  const details_area = {
    paddingLeft: '20px',
    width: '40%'  // Adjust width to ensure space is balanced
  };

  useEffect(() => {
    if (filter) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current);
      }
      debounceRef.current = setTimeout(async () => {
        setLoading(true);
        const results = await fetchFindMonitoredProduct(assetType, filter);
        if (results && results.status === 'success') {
          setSearchResults(results.data);
        } else {
          setSearchResults([]);
        }
        setLoading(false);
      }, 1000);
    } else {
      setSearchResults([]);
    }
    return () => clearTimeout(debounceRef.current);
  }, [filter]);

  const handleProcessing = async () => {
    try {
      setErrorMessage('');
      if (!selectedTa || !selectedTa.user_ta_id) {
        setErrorMessage(t('please_select_ta'));
        return false;
      }
      if (!productCode) {
        setErrorMessage(t('please_select_company'));
        return false;
      }
      if (!number_of_items || number_of_items <= 0) {
        setErrorMessage(t('please_enter_number_of_items'));
        return false;
      }
      if (!price_paid_per_item) {
        setErrorMessage(t('please_enter_price_per_share'));
        return false;
      }
      if (!purchaseDate) {
        setErrorMessage(t('please_select_the_purchase_date'));
        return false;
      }

      setIsProcessing(true); // Start processing

      const data = await fetchBuyAssetsSharesEtfs(assetType, productCode, selectedTa.user_ta_id, price_paid_per_item, number_of_items, purchaseDate.toISOString().split('T')[0], purchaseCurrency);
      if (data) {
        setTaskCompleted(true);
        setSuccessMessage('COMPLETED SUCCESSFULLY');
        navigate('/assets/' + assetType);
        handleRefreshAssets();
      } else {
        setErrorMessage('ERROR ON FETCHING DATA');
      }
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setIsProcessing(false); // Stop processing regardless of the outcome
    }
  };

  const handleClose = () => {
    if (!isProcessing) {
      setPurchaseCurrency('');
      setNumberOfItems('');
      setPricePaidPerItem('');
      setSelectedTa('');
      setTaskCompleted(false);
      setSuccessMessage(null);
      navigate('/assets/' + assetType);
    }
  };

  // Load for buy more
  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
      setIsProcessing(false);
      // Find the selected trading account based on asset.user_ta_id
      if (asset) {
      const selectedTradingAccount = asset?.user_ta_id
        ? trading_accounts.find((ta) => ta.user_ta_id === asset.user_ta_id)
        : null;

      const initialSelectedProduct = { product_label: asset.user_asset_label, product_code: asset.product_code };
      setSelectedProduct(initialSelectedProduct);
      setProductCode(asset.product_code || '');
      console.log('asset:', asset);
      setSelectedTa(selectedTradingAccount);
      setPurchaseCurrency(asset.user_asset_product_currency || '');
      }
    }
  }, [isOpen, asset, trading_accounts]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{t('create_new_asset_title_' + assetType)}</DialogTitle>
        {errorMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="error-container">
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        {successMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="success-container">
              <Typography className="success-text">{successMessage}</Typography>
            </div>
          </div>
        )}
        {!taskCompleted && (
          <>
            <ListItem
              disablePadding
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '560px',
                margin: '20px',
                paddingRight: '20px',
              }}
            >
              <FormControl>
                <Autocomplete
                  freeSolo
                  disableClearable
                  options={searchResults}
                  getOptionLabel={(option) =>
                    `${option.product_label || ''} (${option.product_code || ''})`
                  } // Displayed in the input after selection
                  renderOption={(props, option) => {
                    let country = option.product_country.replace(/\s/g, '_').toLowerCase();
                    const countryIconUrl = `/flags/${country}.svg`;

                    return (
                      <li {...props}>
                        <img
                          src={countryIconUrl}
                          alt={country}
                          style={{ width: 24, height: 24, marginRight: 10 }}
                        />
                        {`${option.product_label} (${option.product_code})`}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search and select share"
                      variant="outlined"
                      onChange={(e) => {
                        setFilter(e.target.value);
                        if (!e.target.value) {
                          setSearchResults([]); // Clear results if input is cleared
                        }
                      }}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading ? <CircularProgress color="inherit" size={20} /> : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                  value={selectedShare}
                  onChange={(event, newValue) => {
                    setSelectedProduct(newValue);
                    setProductCode(newValue?.product_code || ''); // Save company code
                    setPurchaseCurrency(newValue?.product_currency || ''); // Assuming you also want to manage the currency
                  }}
                  style={{ width: '560px' }}
                />
              </FormControl>
            </ListItem>
            <ListItem
              disablePadding
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '560px',
                margin: '20px',
                paddingRight: '20px',
              }}
            >
              <Autocomplete
                options={trading_accounts}
                getOptionLabel={(option) => option.user_ta_label || ''}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('select_brokerage_account')}
                    variant="outlined"
                  />
                )}
                value={selectedTa}
                onChange={(event, newValue) => {
                  setSelectedTa(newValue);
                }}
                style={{ width: '330px' }}
              />
              <ListItemText
                primary={
                  <span style={details_area}>
                    {t('select_ta_brokerage')} /
                    <span
                      onClick={() => navigate('/assets/' + assetType + '/create-brokerage')}
                      style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                    >
                      {t('create_new_brokerage_account')}
                    </span>
                  </span>
                }
              />
            </ListItem>
            <ListItem
              disablePadding
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '560px',
                margin: '20px',
                paddingRight: '20px',
              }}
            >
              <NumericFormat
                customInput={TextField}
                label={t('number_of_items_' + assetType)}
                variant="outlined"
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={'.'}
                decimalSeparator={','}
                onValueChange={(values) => {
                  const { value } = values;
                  setNumberOfItems(value);
                }}
                style={{ width: '330px' }}
              />
              <ListItemText
                primary={t('number_of_items_to_buy_' + assetType)}
                style={{ paddingLeft: '20px', width: '400px' }}
              />
            </ListItem>
            <ListItem
              disablePadding
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '560px',
                margin: '20px',
                paddingRight: '20px',
              }}
            >
              <NumericFormat
                customInput={TextField}
                label={t('price_paid_per_item_' + assetType)}
                variant="outlined"
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={'.'}
                decimalSeparator={','}
                onValueChange={(values) => {
                  const { value } = values;
                  setPricePaidPerItem(value);
                }}
                style={{ width: '330px' }}
              />
              <ListItemText
                primary={t('price_paid_per_item_' + assetType)}
                style={{ paddingLeft: '20px', width: '400px' }}
              />
            </ListItem>
            <ListItem
              disablePadding
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '560px',
                margin: '20px',
                paddingRight: '20px',
              }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                style={{ width: '330px' }}
              >
                <DatePicker
                  label={t('opening_date')}
                  value={purchaseDate}
                  onChange={(newValue) => {
                    setPurchaseDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date(
                    Date.UTC(
                      new Date().getUTCFullYear(),
                      new Date().getUTCMonth(),
                      new Date().getUTCDate()
                    )
                  )}
                />
              </LocalizationProvider>
              <ListItemText
                primary={t('data_tracked_from_this_day_onwards')}
                style={{ paddingLeft: '20px', width: '400px' }}
              />
            </ListItem>
          </>
        )}
        <DialogActions>
          <Button onClick={onClose} variant="contained" disabled={isProcessing}>
            {t('close')}
          </Button>
          {!taskCompleted && (
            <Button
              onClick={handleProcessing}
              variant="contained"
              color="primary"
              disabled={isProcessing}
            >
              {isProcessing ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t('save')
              )}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default Modal_AssetBuy_shares_etfs;
