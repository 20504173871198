import React, { useState } from 'react';
import { TextField, Typography, Button, Link, CircularProgress } from '@mui/material';
import { sendEmailVerification, getAuth } from "firebase/auth";
import { useLocation} from 'react-router-dom';
import { getCookieValue } from './App';
import './Unauthenticated.css';
import logopng from './pics/logo.png';
import translations from './config/translations.json';
import { deleteCookie } from './App';
import { t } from './Util_format';

function Resend_registration_email() {
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const emailFromQuery = queryParams.get('email') || '';
  const [email, setEmail] = useState(emailFromQuery);

  const handleResendRegistrationEmail = async () => {
    setIsProcessing(true);
    setSuccessMessage("");
    setError("");
    deleteCookie();

    if (!email.trim()) {
      setError(t("please_insert_email"));
      setIsProcessing(false);
      return;
    }

    const auth = getAuth();

    try {
      const user = auth.currentUser;
      if (user && !user.emailVerified) {
        await sendEmailVerification(user);
        setSuccessMessage(t("registration_email_sent"));
      } else {
        setError(t("user_not_found_or_already_verified"));
      }
    } catch (err) {
      setError(err.message);
      if (err.message === "Firebase: Error (auth/too-many-requests).") {
        setError(t("too_many_requests_try_later"));
      } else {
        setError(err.message);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div id="page_reset_password_form">
      <div className="container">
        <div className="unauthenticated_left_container">
          <img src={logopng} alt={t("logo")} className="login_logo_desktop" />
          <div className="overlayImage"></div>
        </div>

        <div className="unauthenticated_right_container">
          <Typography variant="h5" sx={{ fontWeight: 'bold', textAlign: 'center' }}>
            {t("resend_registration_title")}
          </Typography>

          <Typography variant="body2" gutterBottom style={{ textAlign: 'center' }}>
            {t("resend_registration_description")}
          </Typography>


          <TextField
            label={t("email_address")}
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ borderRadius: '1rem' }}
            className="input_login"
          />

          {error &&
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{error}</Typography>
            </div>
          }

          {successMessage &&
            <div className="success-container">
              <div className="success-icon">✓</div>
              <Typography className="success-text">{successMessage}</Typography>
            </div>
          }
          <Button
            variant="contained"
            color="primary"
            className="unauthenticated_button"
            sx={{
              borderRadius: '1rem',
              textTransform: 'none',
              backgroundColor: isProcessing ? '#AAAAAA' : 'transparent',
              color: isProcessing ? '#FFFFFF' : 'inherit'
            }}
            onClick={handleResendRegistrationEmail}
            disabled={isProcessing}
          >
            {isProcessing ? <CircularProgress size={24} color="inherit" /> : t("send_registration_email")}
          </Button>


          <Typography variant="body2" className="backToLogin">
            <Link href="/login" className="backToLoginLink">
              {t("back_to_login")}
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default Resend_registration_email;
