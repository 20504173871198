import React, { useEffect } from 'react';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Select, MenuItem, FormControl, InputLabel, ThemeProvider, } from '@mui/material';
import { t } from './Util_format';
import { theme } from './theme';
import { fetchSaveSettings } from './Util_API_calls';
import { set } from 'date-fns';

function Modal_LiabilitiesSettings({ onClose, isOpen, currentCurrency, setBaseCurrency, currentshow_PL_in, setshow_PL_in }) {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [baseCurrency, setBaseCurrencyState] = React.useState(currentCurrency); // Default currency
  const [calculatePLfrom, setCalculatePLfrom] = React.useState('ALL'); // Default currency
  const [show_PL_in, setshow_PL_inState] = React.useState('PERCENTAGE'); // Default currency

  // Update baseCurrency state when currentCurrency prop changes
  useEffect(() => {
    setBaseCurrencyState(currentCurrency);
  }, [currentCurrency]);

  // Update show_PL_in state when currentshow_PL_in prop changes
  useEffect(() => {
    setshow_PL_inState(currentshow_PL_in);
  }, [currentshow_PL_in]);

  const currencyOptions = {
    'JPY': t('currency_JPY') + ' (JPY)',
    'GBP': t('currency_GBP') + ' (GBP)',
    'RMB': t('currency_RMB') + ' (RMB)',
    'IDR': t('currency_IDR') + ' (IDR)',
    'NOK': t('currency_NOK') + ' (NOK)',
    'NZD': t('currency_NZD') + ' (NZD)',
    'CAD': t('currency_CAD') + ' (CAD)',
    'HKD': t('currency_HKD') + ' (HKD)',
    'THB': t('currency_THB') + ' (THB)',
    'USD': t('currency_USD') + ' (USD)',
    'AUD': t('currency_AUD') + ' (AUD)',
    'EUR': t('currency_EUR') + ' (EUR)',
    'CNY': t('currency_CNY') + ' (CNY)',
    'CHF': t('currency_CHF') + ' (CHF)',
    'RUB': t('currency_RUB') + ' (RUB)',
    'SGD': t('currency_SGD') + ' (SGD)',
    'INR': t('currency_INR') + ' (INR)'
  };
  const sortedCurrencyOptions = Object.entries(currencyOptions).sort((a, b) => a[1].localeCompare(b[1]));

  const calculateProfitOptions = {
    'ALL': t('profit_all'),
    '1W': t('profit_this_year'),
    '1M': t('profit_this_month'),
    '1Q': t('profit_this_quarter'),
    '1Y': t('profit_this_week'),
    'TODAY': t('profit_today'),
  };

  const sortedcalculateProfitOptions = Object.entries(calculateProfitOptions);

  const show_PL_inOptions = {
    'CURRENCY': t('pl_in_currency'),
    'PERCENTAGE': t('pl_in_percentage'),
  }

  const sortedshow_PL_inOptions = Object.entries(show_PL_inOptions);

  const handlebaseCurrencyChange = (event) => {
    const newBaseCurrency = event.target.value;
    setBaseCurrencyState(newBaseCurrency); // Update local state instead of parent state
  };

  const handleProcessing = async () => {
    try {
      setIsProcessing(true); // Start processing
      const data = await fetchSaveSettings(baseCurrency, calculatePLfrom, show_PL_in);
      setBaseCurrency(baseCurrency); // Update the parent state here after saving settings
      setshow_PL_in(show_PL_in); // Update the parent state here after saving settings
      onClose(); // Closes the modal after successful operation
    } catch (error) {
      console.error('Error fetching bank category data:', error);
    } finally {
      setIsProcessing(false); // Stop processing regardless of the outcome
    }
  };

  const inside_box = {
    color: '#34403A',
    fontSize: '0.875rem',
    fontFamily: 'Poppins',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    paddingBottom: '10px',
    borderBottom: '1px solid #f0f0f0',
    width: '100%'
  };

  const handleCalculatePLfromChange = (event) => {
    setCalculatePLfrom(event.target.value);
  };

  const handleshow_PL_inChange = (event) => {
    setshow_PL_inState(event.target.value);
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
        <DialogTitle className='modal_title'>
          {t('settings_title')}
        </DialogTitle>
        {errorMessage && (
          <div style={{ paddingLeft: '40px', paddingRight: '40px' }}>
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        <DialogContent style={{ paddingTop: '10px' }}>
          <FormControl fullWidth>
            <InputLabel id="baseCurrency-label">{t('baseCurrency')}</InputLabel>
            <Select
              labelId="baseCurrency-label"
              id="baseCurrency"
              value={baseCurrency}
              label={t('baseCurrency')}
              onChange={handlebaseCurrencyChange}
            >
              {sortedCurrencyOptions.map(([code, name]) => (
                <MenuItem key={code} value={code}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="show_PL_in-label">{t('show_PL_in')}</InputLabel>
            <Select
              labelId="show_PL_in-label"
              id="show_PL_in"
              value={show_PL_in}
              label={t('show_PL_in')}
              onChange={handleshow_PL_inChange}
            >
              {sortedshow_PL_inOptions.map(([code, name]) => (
                <MenuItem key={code} value={code}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel id="calculatePLfrom-label">{t('calculate_PL_from')}</InputLabel>
            <Select
              labelId="calculatePLfrom-label"
              id="calculatePLfrom"
              value={calculatePLfrom}
              disabled={true}
              label={t('calculate_PL_from')}
              onChange={handleCalculatePLfromChange}
            >
              {sortedcalculateProfitOptions.map(([code, name]) => (
                <MenuItem key={code} value={code}>{name}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: 'gray', // Overrides the primary color
              color: 'white',
              '&:hover': {
                backgroundColor: 'darkgray',
              },
            }}
            onClick={onClose}
            variant="contained"
            disabled={isProcessing}
          >
            {t('close')}
          </Button>
          <Button
            onClick={handleProcessing}
            variant="contained"
            color="primary"
            disabled={isProcessing}
          >
            {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('save')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );

}

export default Modal_LiabilitiesSettings;
