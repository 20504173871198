import React, { useState, useEffect } from 'react';
import { getCookieValue } from './App';
import './Unauthenticated.css';
import logopng from './pics/logo.png';
import translations from './config/translations.json';
import SumsubWebSdk from '@sumsub/websdk-react'
import { useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { t } from './Util_format';

function Kyc() {
  const [accessToken, setAccessToken] = useState(null);
  const [userEmail, setUserEmail] = useState(null); // Added state for email
  const firebaseToken = getCookieValue('firebaseToken');
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchAccessToken() {
      try {
        const response = await fetch('https://dev-cf.firstdigitaltrade.eu/api/kyc/auth/issue-token', {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Authorization': 'Bearer ' + firebaseToken
          }
        });

        if (response.status === 401) {
          navigate('/login?action=clean-cookie&reason=session_expired');
          return;
        }

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setAccessToken(data.token);
        setUserEmail(data.email);  // Set the email state with the email from the API response

      } catch (error) {
        console.error('There was a problem fetching the access token:', error);
      }
    }

    fetchAccessToken();
  }, []);

  function handleMessage(messageType, payload) {

  }

  const accessTokenExpirationHandler = () => {

  }

  const handleLogout = () => {
    navigate('/login?action=clean-cookie&reason=user_logout');
  };

  return (
    <div className='kcy_main_div'>
      <div className='kyc_process_bg' ></div>
      <div className='kcy_inside_div'>

        {accessToken && userEmail ? ( // Also check for userEmail before rendering SumsubWebSdk
          <SumsubWebSdk
            accessToken={accessToken}
            expirationHandler={accessTokenExpirationHandler}
            config={{ email: userEmail }}  // Pass the userEmail state here
            options={{ adaptIframeHeight: true }}
            onMessage={handleMessage}
          />
        ) : (
          <p></p>
        )}
        <Button

          color="primary"
          className="kyc_logout_button"
          onClick={handleLogout}
        >
          {t("logout")}
        </Button>
      </div>
    </div>
  );
}

export default Kyc;
