import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useNavigate, useLocation, } from 'react-router-dom';
import Login from './Login';
import Login_otp from './Login_otp';
import Register from './Register';
import Kyc from './Kyc';
import Auth_action from './Auth_action';
import Reset_password_form from './Reset_password_form';
import Structure_logged from './Structure_logged';
import Page_Home from './Page_Home';
import Page_Profile from './Page_Profile';
import Page_Assets from './Page_Assets';
import Page_Liabilities from './Page_Liabilities';
import Page_Transactions from './Page_Transactions';
import Resend_registration_email from './Resend_registration_email';
import Page_Contactus from './Page_Contactus';
import Kill_sessions from './Kill_sessions';
import firebaseConfig from './config/firebase_config.json';
import { initializeApp } from "firebase/app";
import flagit from './pics/it.webp';
import flagen from './pics/en.webp';
import config from './config/env.json';

import './App.css';

const RedirectIfNoCookie = () => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('YourCookieName='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;

    if (!cookieValue) {
        return <Navigate to="/login" />;
    } else {
        return null;
    }
}

const LanguageFlagWrapper = () => {
    const location = useLocation();

    switch (location.pathname) {
        case "/login":
        case "/login_otp":
        case "/register":
        case "/auth_action":
        case "/reset_password_form":
        case "/resend_registration_email":
        case "/kyc":
            break;
        default:
            return null; // Return nothing for any other routes
    }

    return (
        <div className="language-flags">
            <img
                src={flagit}
                alt="Italian"
                title="Sito in Italiano"
                onClick={() => changeLanguage('it')}
            />
            <img
                src={flagen}
                alt="English"
                title="Change language to English"
                onClick={() => changeLanguage('en')}
            />
        </div>
    );
};

const SessionManager = () => {
    const navigate = useNavigate();

    // This function will set a new cookie with the updated expiration time
    const resetSessionTimer = () => {
        // Set the expiration time to 5 minutes from the current time
        const newExpirationTime = new Date(new Date().getTime() + config.INACTIVITY_LOGOUT_TIME_IN_MINUTES * 60000).toUTCString();
        setCookieValue('inactivity_session_expiration_time', newExpirationTime);
        // console.log('Session reset at:', newExpirationTime);
    };

    useEffect(() => {
        // This function is called when user interaction is detected
        const handleUserAction = () => {
            resetSessionTimer(); // Reset the session timer upon user action
        };

        // Set up event listeners for user interaction
        window.addEventListener('click', handleUserAction);
        window.addEventListener('keydown', handleUserAction);

        // This interval will check for session expiration
        const interval = setInterval(() => {
            const currentTime = new Date().getTime();

            // Get the stored cookie values for firebase and inactivity expiration
            const firebaseExpirationTime = getCookieValue('firebase_session_expiration_time');
            const inactivityExpirationTime = getCookieValue('inactivity_session_expiration_time');

            // Calculate time left before expiration
            const firebaseTimeLeft = (new Date(firebaseExpirationTime).getTime() - currentTime) / 1000;
            const inactivityTimeLeft = (new Date(inactivityExpirationTime).getTime() - currentTime) / 1000;

            // Check if either session has expired
            if (firebaseTimeLeft <= 0 || inactivityTimeLeft <= 0) {
                clearInterval(interval); // Stop the interval
                deleteCookie();          // Clear all cookies
                navigate('/login?action=clean-cookie&reason=session_expired');      // Redirect to the login page
            } else {
                // Optionally log the time left for each session (for debugging purposes)
                if (!isNaN(firebaseTimeLeft)) {
                    //        // console.log(`Firebase time left: ${Math.floor(firebaseTimeLeft)} seconds`);
                }
                if (!isNaN(inactivityTimeLeft)) {
                    //        // console.log(`Inactivity time left: ${Math.floor(inactivityTimeLeft)} seconds`);
                }
            }
        }, 1000);

        // This will be called when the component unmounts
        return () => {
            clearInterval(interval); // Clear the interval
            // Remove event listeners to prevent memory leaks
            window.removeEventListener('click', handleUserAction);
            window.removeEventListener('keydown', handleUserAction);
        };
    }, [navigate]); // Only re-run the effect if navigate changes

    return null; // This component doesn't render anything
};

function LoggedInWrapper({ dashboard_structure, PageContent }) {
    return (
        <div>
            {React.cloneElement(dashboard_structure, { children: PageContent })}
        </div>
    );
}

export const changeLanguage = (language) => {
    document.cookie = `language=${language}; path=/; samesite=strict`;
    window.location.reload();
}

export const setCookieValue = (name, value) => {
    document.cookie = `${name}=${value}; path=/; samesite=strict`;
};

export const getCookieValue = (name) => {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const deleteCookie = () => {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:01 GMT; path=/;";
    }
};

const App = () => {

    const [UserMessages, setUserMessages] = useState([]);

    const handleUpdateNotificationBell = (PageData) => {
        // Check if the 'page_dashboard' element exists in the DOM as ih has to run only if the user is logged
        if (document.getElementById('page_dashboard')) {
            if (Array.isArray(PageData.messages)) {
                const UserMessages = PageData.messages;
                setUserMessages(UserMessages);
                return UserMessages;
            } else {
                // console.log("PageData.messages is not an array.");
            }
        } else {
            // console.log("Element with ID 'page_dashboard' does not exist.");
        }
        // Return 0 if 'page_dashboard' does not exist or PageData.messages is not an array
        return 0;
    };


    return (
        <Router>
            <SessionManager />
            <LanguageFlagWrapper />
            <Routes>
                <Route path="/" element={<RedirectIfNoCookie />} />
                <Route path="/login" element={<Login />} />
                <Route path="/login_otp" element={<Login_otp />} />
                <Route path="/kyc" element={<Kyc />} />
                <Route path="/register" element={<Register />} />
                <Route path="/auth_action" element={<Auth_action />} />
                <Route path="/reset_password_form" element={<Reset_password_form />} />
                <Route path="/resend_registration_email" element={<Resend_registration_email />} />
                <Route path="/kill_sessions" element={<Kill_sessions />} />
                <Route
                    path="/home"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Home onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/profile"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Profile onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/assets"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Assets onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/assets/:URLassetType"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Assets onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/assets/create"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Assets />}
                        />
                    }
                />
                <Route
                    path="/assets/:URLassetType/:operation"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Assets />}
                        />
                    }
                />
                <Route
                    path="/assets/edit/"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Assets onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/assets/:URLassetType/:operation/:assetID"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Assets onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/liabilities"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Liabilities onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/liabilities/:URLliabilityType"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Liabilities onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/liabilities/create"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Liabilities />}
                        />
                    }
                />
                <Route
                    path="/liabilities/:URLliabilityType/:operation"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Liabilities />}
                        />
                    }
                />
                <Route
                    path="/liabilities/edit/"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Liabilities onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/liabilities/:URLliabilityType/:operation/:liabilityID"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Liabilities onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/transactions"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Transactions onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/user-guide"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
                <Route
                    path="/contact-us"
                    element={
                        <LoggedInWrapper
                            dashboard_structure={<Structure_logged UserMessages={UserMessages} />}
                            PageContent={<Page_Contactus onDataChange={handleUpdateNotificationBell} />}
                        />
                    }
                />
            </Routes>
        </Router>
    );
}

// Initialize Firebase
initializeApp(firebaseConfig);

export default App;