import React from 'react';

export const getCurrencySymbol = (currencyCode) => {
  return currencySymbols[currencyCode] || currencyCode;
};

const currencySymbols = {
  USD: '$', // US Dollar
  EUR: '€', // Euro
  GBP: '£', // British Pound
  GBX: '£', // British Pound cent
  ALL: 'L', // Albanian Lek
  DZD: 'د.ج', // Algerian Dinar
  AOA: 'Kz', // Angolan Kwanza
  ARS: '$', // Argentine Peso
  AUD: 'A$', // Australian Dollar
  AZN: '₼', // Azerbaijani Manat
  BSD: '$', // Bahamian Dollar
  BHD: '.د.ب', // Bahraini Dinar
  BDT: '৳', // Bangladeshi Taka
  AMD: '֏', // Armenian Dram
  BBD: '$', // Barbadian Dollar
  BMD: '$', // Bermudian Dollar
  BTN: 'Nu.', // Bhutanese Ngultrum
  BOB: 'Bs.', // Bolivian Boliviano
  BWP: 'P', // Botswana Pula
  BRL: 'R$', // Brazilian Real
  BND: '$', // Brunei Dollar
  BGN: 'лв', // Bulgarian Lev
  MMK: 'K', // Burmese Kyat
  BIF: 'FBu', // Burundian Franc
  KHR: '៛', // Cambodian Riel
  CAD: '$', // Canadian Dollar
  CVE: '$', // Cape Verdean Escudo
  KYD: '$', // Cayman Islands Dollar
  CLP: '$', // Chilean Peso
  CNY: '¥', // Chinese Yuan
  COP: '$', // Colombian Peso
  KMF: 'CF', // Comorian Franc
  CRC: '₡', // Costa Rican Colón
  HRK: 'kn', // Croatian Kuna
  CUP: '$', // Cuban Peso
  CZK: 'Kč', // Czech Koruna
  DKK: 'kr', // Danish Krone
  DJF: 'Fdj', // Djiboutian Franc
  DOP: '$', // Dominican Peso
  EGP: '£', // Egyptian Pound
  ETB: 'Br', // Ethiopian Birr
  FJD: '$', // Fijian Dollar
  GMD: 'D', // Gambian Dalasi
  GEL: '₾', // Georgian Lari
  GHS: '₵', // Ghanaian Cedi
  GIP: '£', // Gibraltar Pound
  GTQ: 'Q', // Guatemalan Quetzal
  GNF: 'FG', // Guinean Franc
  GYD: '$', // Guyanese Dollar
  HTG: 'G', // Haitian Gourde
  HNL: 'L', // Honduran Lempira
  HKD: '$', // Hong Kong Dollar
  HUF: 'Ft', // Hungarian Forint
  ISK: 'kr', // Icelandic Króna
  INR: '₹', // Indian Rupee
  IDR: 'Rp', // Indonesian Rupiah
  IRR: '﷼', // Iranian Rial
  IQD: 'ع.د', // Iraqi Dinar
  ILS: '₪', // Israeli New Shekel
  JMD: '$', // Jamaican Dollar
  JPY: '¥', // Japanese Yen
  JOD: 'د.ا', // Jordanian Dinar
  KZT: '₸', // Kazakhstani Tenge
  KES: 'Sh', // Kenyan Shilling
  KRW: '₩', // South Korean Won
  KWD: 'د.ك', // Kuwaiti Dinar
  KGS: 'с', // Kyrgyzstani Som
  LAK: '₭', // Lao Kip
  LBP: 'ل.ل', // Lebanese Pound
  LSL: 'L', // Lesotho Loti
  LRD: '$', // Liberian Dollar
  LYD: 'ل.د', // Libyan Dinar
  MOP: 'MOP$', // Macanese Pataca
  MWK: 'MK', // Malawian Kwacha
  MYR: 'RM', // Malaysian Ringgit
  MVR: 'Rf', // Maldivian Rufiyaa
  MRO: 'UM', // Mauritanian Ouguiya
  MUR: '₨', // Mauritian Rupee
  MXN: '$', // Mexican Peso
  MNT: '₮', // Mongolian Tugrik
  MDL: 'L', // Moldovan Leu
  MAD: 'د.م.', // Moroccan Dirham
  MZN: 'MT', // Mozambican Metical
  NAD: '$', // Namibian Dollar
  NPR: '₨', // Nepalese Rupee
  ANG: 'ƒ', // Netherlands Antillean Guilder
  NZD: '$', // New Zealand Dollar
  NIO: 'C$', // Nicaraguan Córdoba
  NGN: '₦', // Nigerian Naira
  NOK: 'kr', // Norwegian Krone
  OMR: 'ر.ع.', // Omani Rial
  PKR: '₨', // Pakistani Rupee
  PAB: 'B/.', // Panamanian Balboa
  PGK: 'K', // Papua New Guinean Kina
  PYG: '₲', // Paraguayan Guarani
  PEN: 'S/.', // Peruvian Nuevo Sol
  PHP: '₱', // Philippine Peso
  PLN: 'zł', // Polish Złoty
  QAR: 'ر.ق', // Qatari Rial
  RON: 'lei', // Romanian Leu
  RUB: '₽', // Russian Ruble
  RWF: 'FRw', // Rwandan Franc
  SHP: '£', // Saint Helena Pound
  SAR: 'ر.س', // Saudi Riyal
  SCR: '₨', // Seychellois Rupee
  SGD: 'sg$', // Singapore Dollar
  SBD: '$', // Solomon Islands Dollar
  SOS: 'Sh', // Somali Shilling
  ZAR: 'R', // South African Rand
  SSP: '£', // South Sudanese Pound
  LKR: '₨', // Sri Lankan Rupee
  SDG: 'ج.س.', // Sudanese Pound
  SRD: '$', // Surinamese Dollar
  SZL: 'L', // Swazi Lilangeni
  SEK: 'kr', // Swedish Krona
  CHF: 'CHF', // Swiss Franc
  SYP: '£', // Syrian Pound
  TWD: 'NT$', // New Taiwan Dollar
  TJS: 'ЅМ', // Tajikistani Somoni
  TZS: 'Sh', // Tanzanian Shilling
  THB: '฿', // Thai Baht
  TOP: 'T$', // Tongan Paʻanga
  TTD: 'TT$', // Trinidad and Tobago Dollar
  TND: 'د.ت', // Tunisian Dinar
  TRY: '₺', // Turkish Lira
  TMT: 'm', // Turkmenistan Manat
  UGX: 'Sh', // Ugandan Shilling
  UAH: '₴', // Ukrainian Hryvnia
  AED: 'د.إ', // United Arab Emirates Dirham
  UYU: '$', // Uruguayan Peso
  UZS: "so'm", // Uzbekistani So'm
  VUV: 'Vt', // Vanuatu Vatu
  VEF: 'Bs', // Venezuelan Bolívar
  VND: '₫', // Vietnamese đồng
  YER: '﷼', // Yemeni Rial
  ZMW: 'ZK', // Zambian Kwacha
  ZWL: '$', // Zimbabwean Dollar
};