import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, Select, Button, MenuItem, InputLabel, FormControl, ListItemIcon, ListItemText, Box, useMediaQuery, ThemeProvider, TextField, styled } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchTrendChartData, get_page_data } from './Util_API_calls';
import { t } from './Util_format';
import config from './config/env.json';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CollectionsIcon from '@mui/icons-material/Collections';
import BarChartIcon from '@mui/icons-material/BarChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HomeIcon from '@mui/icons-material/Home';
import SavingsIcon from '@mui/icons-material/Savings';
import SecurityIcon from '@mui/icons-material/Security';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PieChartIcon from '@mui/icons-material/PieChart';
import { Table_assets_shares_etfs_cryptos_commodities } from './Table_assets_shares_etfs_cryptos_commodities';
import { Table_assets_bankaccounts_credits_deposits } from './Table_assets_bankaccounts_credits_deposits';
import { Table_assets_collectibles } from './Table_assets_collectibles';
import { Table_assets_businesses } from './Table_assets_businesses';
import { Table_assets_others } from './Table_assets_others';
import { Table_assets_supers } from './Table_assets_supers';
import { Table_assets_bonds } from './Table_assets_bonds';
import { Table_assets_vehicles_realestates } from './Table_assets_vehicles_realestates';
import { Table_assets_all } from './Table_assets_all';
import { Modal_TaCreate } from './Modal_TaCreate';
import { Modal_TaList } from './Modal_TaList';
import { Modal_InstitutionsList } from './Modal_InstitutionsList';
import { Modal_InstitutionCreate } from './Modal_InstitutionCreate';
import Modal_AssetsActions from './Modal_AssetsActions';
import Modal_AssetsSettings from './Modal_AssetsSettings';
import Modal_AssetCreate_all from './Modal_AssetCreate_all';
import Modal_AssetCreate_bankaccount from './Modal_AssetCreate_bankaccount';
import Modal_AssetCreate_credit from './Modal_AssetCreate_credit';
import Modal_AssetBuy_shares_etfs from './Modal_AssetBuy_shares_etfs';
import Modal_AssetBuy_commodities from './Modal_AssetBuy_commodities';
import Modal_AssetSell_shares_etfs from './Modal_AssetSell_shares_etfs';
import Modal_AssetSell_comodities from './Modal_AssetSell_commodities';
import Modal_AssetCreate_vehicle from './Modal_AssetCreate_vehicle';
import Modal_AssetCreate_realestate from './Modal_AssetCreate_realestate';
import Modal_AssetDeleteCloseBankAccount from './Modal_AssetDeleteCloseBankAccount';
import Modal_AssetDeleteCloseCredit from './Modal_AssetDeleteCloseCredit';
import Modal_AssetDeleteCloseVehicle from './Modal_AssetDeleteCloseVehicle';
import Modal_AssetDeleteCloseRealEstate from './Modal_AssetDeleteCloseRealEstate';
import { PieChart, Pie, Cell, ResponsiveContainer, LabelList } from 'recharts';
import { theme, PIE_COLORS } from './theme';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { set } from 'date-fns';
Chart.register(...registerables);

const Icons = {
  ListAltIcon,
  AccountBalanceIcon,
  ShowChartIcon,
  BusinessCenterIcon,
  CollectionsIcon,
  BarChartIcon,
  CreditCardIcon,
  CurrencyBitcoinIcon,
  TrendingUpIcon,
  MoreHorizIcon,
  HomeIcon,
  SavingsIcon,
  SecurityIcon,
  StackedLineChartIcon,
  DirectionsCarIcon,
};

export const cellTextStyle = {
  color: 'theme.typography.body1.color', // Use the text color from the theme
};

const iconStyle = {
  color: '#2a9461',
  height: '24px',
  width: '24px'
};

const menuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '48px'
};

const textStyle_small_light = {
  fontWeight: 500,
  color: '#34403A',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

function Page_Assets({ onDataChange, ...props }) {
  const [category, setCategory] = React.useState('');
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [assets, setAssets] = useState([]);
  const [user_institutions, setUserInstitutions] = useState([]);
  const [trading_accounts, setTradingAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem('currentPage');
    return savedPage !== null ? parseInt(savedPage, 10) : 0;
  });

  const { URLassetType } = useParams(); // Grab the URLassetType parameter from the URL
  const navigate = useNavigate();
  const [totalPL, setTotalPL] = useState(0);
  const [dailyTotals, setDailyTotals] = useState([]);
  const [PieDataTotal, setPieDataTotal] = useState(0);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [base_currency, setBaseCurrency] = useState('USD'); // Initialize with default currency
  const [show_PL_in, setshow_PL_in] = useState('CURRENCY'); // Initialize with default currency
  const [hoveredPieSliceIndices, setHoveredPieSliceIndices] = useState({});
  const [timeFrame, setTimeFrame] = useState('1M'); // Default to 1 month
  const [isLoadingAssets, setIsLoadingAssets] = useState(true);
  const [isLoadingTrendChart, setIsLoadingTrendChart] = useState(true);

  const { operation, assetID } = useParams();

  useEffect(() => {
    // BUY
    if (operation === 'buy' && URLassetType === 'shares') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetBuySharesModalOpen(true);
    }
    if (operation === 'buy' && URLassetType === 'etfs') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetBuyEtfsModalOpen(true);
    }
    if (operation === 'buy' && URLassetType === 'commodities') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetBuyCommoditiesModalOpen(true);
    }
    // SELL
    if (operation === 'sell' && URLassetType === 'shares' && assetID && assets && assets.length > 0) {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetSellSharesModalOpen(true);
    }
    if (operation === 'sell' && URLassetType === 'etfs' && assetID && assets && assets.length > 0) {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetSellEtfsModalOpen(true);
    }
    if (operation === 'sell' && URLassetType === 'commodities' && assetID && assets && assets.length > 0) {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetSellCommoditiesModalOpen(true);
    }
    // CREATE
    if (operation === 'create' && URLassetType === 'bankaccounts') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetCreateBankAccountModalOpen(true);
    }
    if (operation === 'list-banks' && URLassetType === 'bankaccounts') {
      setIsAssetCreateBankAccountModalOpen(false);
      handleOpenInstitutionsListModal(true);
    }
    if (operation === 'create-bank' && URLassetType === 'bankaccounts') {
      setIsAssetCreateBankAccountModalOpen(false);
      handleOpenInstitutionCreateModal(true);
      setIsTaCreateModalOpen(false);
    }
    if (operation === 'create-brokerage' && URLassetType === 'shares') {
      setIsTaCreateModalOpen(true);
      setisAssetBuySharesModalOpen(false);
    }
    if (operation === 'create-brokerage' && URLassetType === 'etfs') {
      setIsTaCreateModalOpen(true);
      setisAssetBuyEtfsModalOpen(false);
    }
    if (operation === 'create' && URLassetType === 'credits') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetCreateCreditModalOpen(true);
    }
    // DELETE
    if (operation === 'delete' && URLassetType === 'bankaccounts') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetDeleteBankAccountModalOpen(true);
    }
    if (operation === 'delete' && URLassetType === 'credits') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetDeleteCreditModalOpen(true);
    }
    if (operation === 'delete' && URLassetType === 'vehicles') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetDeleteVehicleModalOpen(true);
    }
    if (operation === 'delete' && URLassetType === 'realestates') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetDeleteRealEstateModalOpen(true);
    }

  }, [operation, URLassetType, assets]);

  useEffect(() => {
    // set asset.dynamic_current_quantity to 1 if not set (real estate, collectibles etc.)
    if (assets) {
      assets.forEach((asset) => {
        if (!asset.dynamic_current_quantity) {
          asset.dynamic_current_quantity = 1;
        }
      });
    }

    let initialPL = null;
    const total_PL = assets.reduce((acc, asset) => {
      if (asset.PL_unit && asset.PL_unit[base_currency]) {
        return (acc === null ? 0 : acc) + asset.PL_unit[base_currency] * asset.dynamic_current_quantity;
      }
      return acc;
    }, initialPL);
    setTotalPL(total_PL === null ? null : total_PL);

    const total_value = assets.reduce((acc, asset) => {
      if (asset.product_last_value && asset.product_last_value[base_currency]) {
        return acc + asset.product_last_value[base_currency] * asset.dynamic_current_quantity;
      }
      if (asset.dynamic_balance && asset.dynamic_balance[base_currency]) {
        return acc + asset.dynamic_balance[base_currency];
      }
      if (asset.last_value && asset.last_value[base_currency]) {
        return acc + asset.last_value[base_currency];
      }
      return acc;
    }, 0);

    setPieDataTotal(total_value);

  }, [assets, base_currency]); // Make sure to include settings.base_currency in dependency array

  useEffect(() => {
    // Find the corresponding category title from the config
    const selectedCategory = config.ASSET_CATEGORIES.find(c => c.user_asset_type === URLassetType);
    if (selectedCategory) {
      setCategory(selectedCategory.user_asset_type);
    }
  }, [URLassetType]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetch_assets_list(newPage);
  };

  const handleChangeAssetType = (event) => {
    setIsLoading(true);
    const selectedCategoryTitle = event.target.value;
    const selectedCategory = config.ASSET_CATEGORIES.find(c => c.user_asset_type === selectedCategoryTitle);
    if (selectedCategory) {
      setCategory(selectedCategory.user_asset_type); // Set the user_asset_type
      navigate(`/assets/${selectedCategory.user_asset_type}`);
    }
  };

  const findCategory = (selected) => {
    return config.ASSET_CATEGORIES.find(item => item.user_asset_type === selected);
  };

  const accountIcon = (type) => {
    const category = config.ASSET_CATEGORIES.find(c => c.user_asset_type === type);
    if (category && category.icon) {
      const IconComponent = Icons[category.icon]; // Assuming you have an object mapping string to icon components
      return <IconComponent style={iconStyle} />;
    }
    return <ListIcon style={iconStyle} />;
  };

  // Fetch Assets List
  const fetch_assets_list = async (page = null) => {
    const currentPage = page !== null ? page : parseInt(localStorage.getItem('currentPage'), 10) || 0;
    setIsLoadingAssets(true);
    const selectedCategory = config.ASSET_CATEGORIES.find(acc => acc.user_asset_type === URLassetType);
    if (!selectedCategory) {
      console.error("No selected category found for category data fetch (" + selectedCategory + ")");
      return;
    }
    setIsLoading(true);
    try {
      setAssets([]);
      const data = await get_page_data('assets', selectedCategory.user_asset_type, currentPage);
      if (data && data.assets) {
        setBaseCurrency(data.settings.base_currency);
        setAssets(data.assets);
        setUserInstitutions(data.user_institutions);
        setTradingAccounts(data.trading_accounts);
      } else {
        setAssets([]);
      }
    } catch (error) {
      console.error('Error fetching assets list:', error);
      setAssets([]);
    } finally {
      setIsLoading(false);
    }
    setIsLoadingAssets(false);
  };


  // Fetch Trend Chart Data
  const fetch_trend_chart_data = async () => {
    setIsLoadingTrendChart(true);

    const selectedCategory = config.ASSET_CATEGORIES.find(acc => acc.user_asset_type === URLassetType);

    // Check if the selectedCategory is not found or user_asset_type is empty
    if (!selectedCategory || !selectedCategory.user_asset_type) {
      console.error("No valid category found for trend chart data fetch.");
      setIsLoadingTrendChart(false);
      return;
    }

    if (!selectedCategory) {
      console.error("No selected category found for trend chart data fetch (" + selectedCategory + ")");
      return;
    }
    try {
      const data = await fetchTrendChartData(selectedCategory.user_asset_type, 'this_year', base_currency);
      if (data && data.daily_totals) {
        setDailyTotals(data.daily_totals);
        // console.log(data.daily_totals);
      } else {
        // console.log("No trend data found for category: ", category);
      }
    } catch (error) {
      console.error('Error fetching trend data:', error);
    }
    setIsLoadingTrendChart(false);
  };

  // useEffect for fetching assets list
  useEffect(() => {
    if (category) {
      fetch_assets_list(currentPage);
    }
  }, [category, URLassetType, currentPage]);

  // useEffect for fetching trend chart data
  useEffect(() => {
    if (base_currency && category) {
      fetch_trend_chart_data();
    }
  }, [base_currency, category]);

  const loss_color = {
    color: '#c0392b',
  };

  const gain_color = {
    color: '#2a9461',
  };

  useEffect(() => {
    localStorage.setItem('currentPage', currentPage);
  }, [currentPage]);

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [isAssetCreateBankAccountModalOpen, setIsAssetCreateBankAccountModalOpen] = useState(false);
  const [isAssetCreateCreditModalOpen, setIsAssetCreateCreditModalOpen] = useState(false);
  const [isAssetBuySharesModalOpen, setisAssetBuySharesModalOpen] = useState(false);
  const [isAssetBuyEtfsModalOpen, setisAssetBuyEtfsModalOpen] = useState(false);
  const [isAssetSellShareModalOpen, setisAssetSellSharesModalOpen] = useState(false);
  const [isAssetSellEtfModalOpen, setisAssetSellEtfsModalOpen] = useState(false);
  const [isAssetSellCommoditiesModalOpen, setisAssetSellCommoditiesModalOpen] = useState(false);
  const [isAssetBuyCommoditiesModalOpen, setisAssetBuyCommoditiesModalOpen] = useState(false);
  const [isAssetDeleteBankAccountModalOpen, setIsAssetDeleteBankAccountModalOpen] = useState(false);
  const [isAssetDeleteCreditModalOpen, setIsAssetDeleteCreditModalOpen] = useState(false);
  const [isAssetCreateVehicleModalOpen, setIsAssetCreateVehicleModalOpen] = useState(false);
  const [isAssetDeleteVehicleModalOpen, setIsAssetDeleteVehicleModalOpen] = useState(false);
  const [isAssetCreateRealEstateModalOpen, setIsAssetCreateRealEstateModalOpen] = useState(false);
  const [isAssetDeleteRealEstateModalOpen, setIsAssetDeleteRealEstateModalOpen] = useState(false);
  const [isTaCreateModalOpen, setIsTaCreateModalOpen] = useState(false);
  const [isTaListModalOpen, setIsTaListModalOpen] = useState(false);
  const [isInstitutionsListModalOpen, setIsInstitutionsListModalOpen] = useState(false);
  const [isInstitutionsCreateModalOpen, setIsInstitutionsCreateModalOpen] = useState(false);
  const [isAssetCreateAllModalOpen, setIsAssetCreateAllModalOpen] = useState(false);
  const [pieData, setPieData] = useState([]);

  const handleOpenSettingsModal = () => {
    setIsSettingsModalOpen(true);
  };

  const handleOpenActionsModal = (asset) => {
    setSelectedAsset(asset);
    navigate(`/assets/${URLassetType}/actions/${asset.user_asset_id}`);
    setIsActionsModalOpen(true);
  };

  const handleOpenAssetCreateBankAccountModal = () => {
    setIsAssetCreateBankAccountModalOpen(true);
    navigate(`/assets/${URLassetType}/create`);
  };

  const handleOpenAssetCreateCreditModal = () => {
    setIsAssetCreateCreditModalOpen(true);
    navigate(`/assets/${URLassetType}/create`);
  }
  const handleOpenAssetBuyShareModal = () => {
    setisAssetBuySharesModalOpen(true);
    navigate(`/assets/${URLassetType}/buy`);
  };

  const handleOpenAssetBuyEtfModal = () => {
    setisAssetBuyEtfsModalOpen(true);
    navigate(`/assets/${URLassetType}/buy`);
  };

  const handleOpenAssetCreateVehicleModal = () => {
    setIsAssetCreateVehicleModalOpen(true);
    navigate(`/assets/${URLassetType}/create`);
  }

  const handleOpenAssetCreateRealEstateModal = () => {
    setIsAssetCreateRealEstateModalOpen(true);
    navigate(`/assets/${URLassetType}/create`);
  }

  const handleOpenTaCreateModal = () => {
    setIsTaListModalOpen(false);
    setIsTaCreateModalOpen(true);
  };

  const handleOpenInstitutionCreateModal = () => {
    setIsInstitutionsCreateModalOpen(true);
  }

  const handleOpenAssetCreateAllModal = () => {
    navigate(`/assets/${URLassetType}/create`);
    setIsAssetCreateAllModalOpen(true);
  }

  const handleOpenInstitutionsListModal = () => {
    setIsInstitutionsListModalOpen(true);
  }

  const handleOpenTaListModal = (asset) => {
    setIsTaListModalOpen(true);
  }

  const handleCloseAssetCreateBankAccountModal = () => {
    setIsAssetCreateBankAccountModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  };

  const handleCloseAssetCreateCreditModal = () => {
    setIsAssetCreateCreditModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetCreateVehicleModal = () => {
    setIsAssetCreateVehicleModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetCreateRealEstateModal = () => {
    setIsAssetCreateRealEstateModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetBuyShareModal = () => {
    setisAssetBuySharesModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetBuyEtfModal = () => {
    setisAssetBuyEtfsModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetBuyCommoditiesModal = () => {
    setisAssetBuyCommoditiesModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseDeleteAssetBankAccountModal = () => {
    setIsAssetDeleteBankAccountModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseDeleteAssetCreditModal = () => {
    setIsAssetDeleteCreditModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseDeleteAssetVehicleModal = () => {
    setIsAssetDeleteVehicleModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseDeleteAssetRealEstateModal = () => {
    setIsAssetDeleteRealEstateModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetSellShareModal = () => {
    setisAssetSellSharesModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetSellEtfModal = () => {
    setisAssetSellEtfsModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetSellCommodityModal = () => {
    setisAssetSellCommoditiesModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseSettingsModal = () => {
    setIsSettingsModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  };

  const [selectedAsset, setSelectedAsset] = useState(null);

  const handleCloseActionsModal = () => {
    setIsActionsModalOpen(false);
    // navigate(`/assets/${URLassetType}`);
  };

  const handleCloseTaCreateModal = () => {
    setIsTaCreateModalOpen(false);
  }

  const handleCloseTaListModal = () => {
    setIsTaListModalOpen(false);
  };

  const handleCloseInstitutionsListModal = () => {
    setIsInstitutionsListModalOpen(false);
  };

  const handleCloseInstitutionsCreateModal = () => {
    setIsInstitutionsCreateModalOpen(false);
  };

  const handleCloseAssetCreatAllModal = () => {
    setIsAssetCreateAllModalOpen(false);
  }

  const renderTableForCategory = (URLassetType) => {
    switch (URLassetType) {
      case "all":
        let assets_aggregated = [];
        let assetTypeIndexMap = {};
        assets.forEach(asset => {
          // Check if this asset type has been encountered before
          if (!(asset.user_asset_type in assetTypeIndexMap)) {
            // Add a new object for this asset type
            assetTypeIndexMap[asset.user_asset_type] = assets_aggregated.length;
            assets_aggregated.push({
              user_asset_type: asset.user_asset_type,
              last_value: {},
              PL_unit: {}
            });
          }

          let index = assetTypeIndexMap[asset.user_asset_type];

          // Aggregate last_value for each currency
          for (const currency in asset.last_value) {
            if (!(currency in assets_aggregated[index].last_value)) {
              assets_aggregated[index].last_value[currency] = 0;
            }
            assets_aggregated[index].last_value[currency] += asset.last_value[currency];
          }

          // Aggregate PL_unit for each currency
          for (const currency in asset.PL_unit) {
            if (!(currency in assets_aggregated[index].PL_unit)) {
              assets_aggregated[index].PL_unit[currency] = 0;
            }
            assets_aggregated[index].PL_unit[currency] += asset.PL_unit[currency];
          }
        });
        return (
          <Table_assets_all
            key={searchTerm}
            assets={assets_aggregated}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
          />
        );
      case 'bankaccounts':
      case 'credits':
      case 'deposits':
        return (
          <Table_assets_bankaccounts_credits_deposits
            assets={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'shares':
      case 'etfs':
      case 'commodities':
      case 'cryptos':
        return (
          <Table_assets_shares_etfs_cryptos_commodities
            key={searchTerm}
            assets={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'collectibles':
        return (
          <Table_assets_collectibles
            key={searchTerm}
            assets={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'businesses':
        return (
          <Table_assets_businesses
            key={searchTerm}
            assets={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'bonds':
        return (
          <Table_assets_bonds
            key={searchTerm}
            assets={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'others':
        return (
          <Table_assets_others
            key={searchTerm}
            assets={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'supers':
        return (
          <Table_assets_supers
            key={searchTerm}
            assets={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'vehicles':
      case 'realestates':
        return (
          <Table_assets_vehicles_realestates
            key={searchTerm}
            assets={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      default:
        return <div>Unsupported asset type: {category}</div>;
    }
  };

  const onPieEnter = (pieInfoToShow, index) => {
    setHoveredPieSliceIndices(prev => ({ ...prev, [pieInfoToShow]: index }));
  };

  const onPieLeave = (pieInfoToShow) => {
    setHoveredPieSliceIndices(prev => ({ ...prev, [pieInfoToShow]: -1 }));
  };

  const renderPiesForCategory = () => {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={pieData}
            dataKey="pie_percentage"
            nameKey="pie_label"
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill="#8884d8"
            labelLine={false}
            label={({ pie_label, pie_percentage, index }) => {
              if (hoveredPieSliceIndices['value'] === index) {
                const formattedValue = `${pie_percentage.toFixed(2)}%`;
                return `${formattedValue} | ${pie_label}`;
              }
              return null;
            }}
            onMouseEnter={(e, index) => onPieEnter('value', index)}
            onMouseLeave={() => onPieLeave('value')}
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-value-${entry.pie_label}-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  };


  useEffect(() => {
    if (assets.length > 0) {
      const buildPieData = () => {
        let assetsAggregated = [];
        let assetTypeIndexMap = {};

        assets.forEach(asset => {
          if (asset.user_asset_type === null || asset.last_value === undefined || !(base_currency in asset.last_value)) {
            return;
          }
          if (!(asset.user_asset_type in assetTypeIndexMap)) {
            assetTypeIndexMap[asset.user_asset_type] = assetsAggregated.length;
            assetsAggregated.push({
              pie_label: asset.user_asset_type,
              pie_percentage: asset.last_value[base_currency],
            });
          } else {
            let index = assetTypeIndexMap[asset.user_asset_type];
            assetsAggregated[index].pie_percentage += asset.last_value[base_currency];
          }
        });

        let pieDataTotal = assetsAggregated.reduce((sum, asset) => sum + asset.pie_percentage, 0);
        assetsAggregated.forEach(asset => {
          asset.pie_percentage = (asset.pie_percentage / pieDataTotal) * 100;
        });

        return assetsAggregated.sort((a, b) => b.pie_percentage - a.pie_percentage);
      };

      setPieData(buildPieData());
    }
  }, [assets, base_currency]);


  const chartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Value of my shares',
        data: dailyTotals,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      },
      // You can add more datasets here
    ],
  };

  const ChartOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    animation: {
      duration: 0 // No animation
    },
    hover: {
      animationDuration: 0 // No animation on hover
    },
    responsiveAnimationDuration: 0 // No animation on window resizing
  };


  const updateChartData = (selectedTimeFrame) => {
    // Logic to update data based on selectedTimeFrame
    // This might involve fetching new data or filtering your dataset
    // For example:
    // if (selectedTimeFrame === '1M') { ... }
    // ...
  };

  let carouselItems = [];

  // Conditionally add the 'sector' item if URLassetType is 'shares'

  carouselItems.push({
    title: 'value',
    renderFunc: () => renderPiesForCategory(URLassetType, 'value'),
  });

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'sector',
      renderFunc: () => renderPiesForCategory(URLassetType, 'sector'),
    });
  }

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'currency',
      renderFunc: () => renderPiesForCategory(URLassetType, 'currency'),
    });
  }

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'country',
      renderFunc: () => renderPiesForCategory(URLassetType, 'country'),
    });
  }

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'PL_up',
      renderFunc: () => renderPiesForCategory(URLassetType, 'PL_up'),
    });
  }

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'PL_down',
      renderFunc: () => renderPiesForCategory(URLassetType, 'PL_down'),
    });
  }

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'brokerage_account',
      renderFunc: () => renderPiesForCategory(URLassetType, 'brokerage_account'),
    });
  }

  const [sortedCategories, setSortedCategories] = useState([]);
  useEffect(() => {
    const firstItem = config.ASSET_CATEGORIES[0];
    const lastItem = config.ASSET_CATEGORIES[config.ASSET_CATEGORIES.length - 1];
    const middleItems = config.ASSET_CATEGORIES.slice(1, -1).sort((a, b) => {
      const labelA = t('categories_assets_' + a.user_asset_type).toLowerCase();
      const labelB = t('categories_assets_' + b.user_asset_type).toLowerCase();
      return labelA.localeCompare(labelB);
    });
    setSortedCategories([firstItem, ...middleItems, lastItem]);
  }, [t, config.ASSET_CATEGORIES]); // Add other dependencies if needed

  const handleRefreshAssets = () => {
    fetch_assets_list(currentPage);
  };

  return (
    <div style={{ marginLeft: 'auto', marginRight: 'auto', maxWidth: '1500px' }}>
      <ThemeProvider theme={theme}>
        {isSettingsModalOpen && (
          <Modal_AssetsSettings
            isOpen={isSettingsModalOpen}
            onClose={handleCloseSettingsModal}
            setBaseCurrency={setBaseCurrency}
            setshow_PL_in={setshow_PL_in}
            currentCurrency={base_currency}
            currentshow_PL_in={show_PL_in}
          />
        )}
        {isActionsModalOpen && (
          <Modal_AssetsActions
            isOpen={isActionsModalOpen}
            onClose={handleCloseActionsModal}
            asset={selectedAsset}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            trading_accounts={trading_accounts}
          />
        )}
        {isTaCreateModalOpen && (
          <Modal_TaCreate
            isOpen={isTaCreateModalOpen}
            onClose={handleCloseTaCreateModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            user_institutions={user_institutions}
            trading_accounts={trading_accounts}
          />
        )}
        {isTaListModalOpen && (
          <Modal_TaList
            isOpen={isTaListModalOpen}
            onClose={handleCloseTaListModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            user_institutions={user_institutions}
            trading_accounts={trading_accounts}
            handleOpenTaCreateModal={handleOpenTaCreateModal}
          />
        )}
        {isInstitutionsListModalOpen && (
          <Modal_InstitutionsList
            isOpen={isInstitutionsListModalOpen}
            onClose={handleCloseInstitutionsListModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            user_institutions={user_institutions}
          />
        )}
        {isInstitutionsCreateModalOpen && (
          <Modal_InstitutionCreate
            isOpen={isInstitutionsCreateModalOpen}
            onClose={handleCloseInstitutionsCreateModal}
            assetType={URLassetType}
            user_institutions={user_institutions}
            handleRefreshAssets={handleRefreshAssets}
          />
        )}
        {isAssetCreateAllModalOpen && (
          <Modal_AssetCreate_all
            isOpen={isAssetCreateAllModalOpen}
            onClose={handleCloseAssetCreatAllModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            user_institutions={user_institutions}
          />
        )}
        {isAssetCreateBankAccountModalOpen && (
          <Modal_AssetCreate_bankaccount
            isOpen={isAssetCreateBankAccountModalOpen}
            onClose={handleCloseAssetCreateBankAccountModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            user_institutions={user_institutions}
          />
        )}
        {isAssetCreateCreditModalOpen && (
          <Modal_AssetCreate_credit
            isOpen={isAssetCreateCreditModalOpen}
            onClose={handleCloseAssetCreateCreditModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            user_institutions={user_institutions}
          />
        )}
        {isAssetCreateVehicleModalOpen && (
          <Modal_AssetCreate_vehicle
            isOpen={isAssetCreateVehicleModalOpen}
            onClose={handleCloseAssetCreateVehicleModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            user_institutions={user_institutions}
          />
        )}
        {isAssetCreateRealEstateModalOpen && (
          <Modal_AssetCreate_realestate
            isOpen={isAssetCreateRealEstateModalOpen}
            onClose={handleCloseAssetCreateRealEstateModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            user_institutions={user_institutions}
          />
        )}
        {isAssetBuySharesModalOpen && (
          <Modal_AssetBuy_shares_etfs
            isOpen={isAssetBuySharesModalOpen}
            onClose={handleCloseAssetBuyShareModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            trading_accounts={trading_accounts}
            asset={selectedAsset}
          />
        )}
        {isAssetBuyEtfsModalOpen && (
          <Modal_AssetBuy_shares_etfs
            isOpen={isAssetBuyEtfsModalOpen}
            onClose={handleCloseAssetBuyEtfModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            trading_accounts={trading_accounts}
            asset={selectedAsset}
          />
        )}
        {isAssetSellShareModalOpen && (
          <Modal_AssetSell_shares_etfs
            isOpen={isAssetSellShareModalOpen}
            onClose={handleCloseAssetSellShareModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            trading_accounts={trading_accounts}
            asset={selectedAsset}
          />
        )}
        {isAssetSellEtfModalOpen && (
          <Modal_AssetSell_shares_etfs
            isOpen={isAssetSellEtfModalOpen}
            onClose={handleCloseAssetSellEtfModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            trading_accounts={trading_accounts}
            asset={selectedAsset}
          />
        )}
        {isAssetSellCommoditiesModalOpen && (
          <Modal_AssetSell_comodities
            isOpen={isAssetSellCommoditiesModalOpen}
            onClose={handleCloseAssetSellCommodityModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            trading_accounts={trading_accounts}
            asset={selectedAsset}
          />
        )}

        {isAssetBuyCommoditiesModalOpen && (
          <Modal_AssetBuy_commodities
            isOpen={isAssetBuyCommoditiesModalOpen}
            onClose={handleCloseAssetBuyCommoditiesModal}
            assetType={URLassetType}
            handleRefreshAssets={handleRefreshAssets}
            trading_accounts={trading_accounts}
          />
        )}
        {isAssetDeleteBankAccountModalOpen && (
          <Modal_AssetDeleteCloseBankAccount
            isOpen={isAssetDeleteBankAccountModalOpen}
            onClose={handleCloseDeleteAssetBankAccountModal}
            asset={selectedAsset}
            handleRefreshAssets={handleRefreshAssets}
          />
        )}
        {isAssetDeleteCreditModalOpen && (
          <Modal_AssetDeleteCloseCredit
            isOpen={isAssetDeleteCreditModalOpen}
            onClose={handleCloseDeleteAssetCreditModal}
            asset={selectedAsset}
            handleRefreshAssets={handleRefreshAssets}
          />
        )}
        {isAssetDeleteVehicleModalOpen && (
          <Modal_AssetDeleteCloseVehicle
            isOpen={isAssetDeleteVehicleModalOpen}
            onClose={handleCloseDeleteAssetVehicleModal}
            asset={selectedAsset}
            handleRefreshAssets={handleRefreshAssets}
          />
        )}
        {isAssetDeleteRealEstateModalOpen && (
          <Modal_AssetDeleteCloseRealEstate
            isOpen={isAssetDeleteRealEstateModalOpen}
            onClose={handleCloseDeleteAssetRealEstateModal}
            asset={selectedAsset}
            handleRefreshAssets={handleRefreshAssets}
          />
        )}

        <Grid container spacing={3} style={{ display: 'flex', justifyContent: 'center', paddingTop: '20px' }}>
          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <CardContent>
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  <FormControl style={{ marginRight: '10px', width: '250px' }}>
                    <InputLabel id="account-select-label" className="assets_input-label">
                      {t('select_asset_type')}
                    </InputLabel>
                    <Select
                      labelId="account-select-label"
                      id="account-select"
                      value={category}
                      label={t('select_asset_type')}
                      onChange={handleChangeAssetType}
                      color="primary"
                      IconComponent={ListIcon}
                      renderValue={(selected) => (
                        <Box display="flex" alignItems="center">
                          {findCategory(selected) ? accountIcon(findCategory(selected).user_asset_type) : null}
                          <Typography variant="body2" noWrap style={{ ...textStyle_small_light, marginLeft: '8px' }}>
                            {t('categories_assets_' + selected)}
                          </Typography>
                        </Box>
                      )}
                    >
                      {sortedCategories.map((item) => (
                        <MenuItem key={item.user_asset_type} value={item.user_asset_type} style={menuItemStyle}>
                          <ListItemIcon>{accountIcon(item.user_asset_type)}</ListItemIcon>
                          <ListItemText primary={t('categories_assets_' + item.user_asset_type)} />
                        </MenuItem>
                      ))}
                    </Select>

                  </FormControl>
                  <TextField
                    style={{ flexGrow: 2, marginRight: '10px' }}
                    name="search_filter_unique"
                    label={t('search')}
                    variant="outlined"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    autoComplete="no-autofill"
                  />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {/* ... other components like TextField */}

                    <Box sx={{
                      border: '1px solid #c4c4c4', // adjust this color to match your inputs border color
                      borderRadius: '4px', // adjust this value to match your theme's border radius
                      paddingLeft: '30px', // adjust this padding to match the TextField's padding
                      paddingRight: '30px', // adjust this padding to match the TextField's padding
                      marginRight: '8px', // adjust margin as needed for spacing
                      position: 'relative',
                      display: 'flex',
                      alignItems: 'center',
                      height: '56px', // adjust this value to match the height of your TextFields
                    }}>

                      <span style={{
                        position: 'absolute',
                        top: '-9px', // adjust this value to correctly position the label as per your design
                        left: '10px', // adjust this value to correctly position the label as per your design
                        background: '#fff', // this should match the background color of your container
                        padding: '0 4px',
                        fontSize: '12px', // adjust this value to match the label font size of your TextFields
                        color: 'rgba(0, 0, 0, 0.6)' // this should match the border color or the color you want the label text to be
                      }}>
                        {t('operations')}
                      </span>
                      {URLassetType === 'all' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenAssetCreateAllModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('record_new_asset_button_' + URLassetType)}
                        </Button>
                      )}
                      {URLassetType === 'bankaccounts' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenInstitutionCreateModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ marginRight: '8px', textTransform: 'capitalize' }}
                        >
                          {t('create_institution')}
                        </Button>
                      )}
                      {URLassetType === 'bankaccounts' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenInstitutionsListModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ marginRight: '8px', textTransform: 'capitalize' }}
                        >
                          {t('list_institutions')}
                        </Button>
                      )}
                      {(URLassetType === 'shares' || URLassetType === 'etfs') && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenTaListModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ marginRight: '8px', textTransform: 'capitalize' }}
                        >
                          {t('show_ta_' + URLassetType)}
                        </Button>
                      )}
                      {URLassetType === 'bankaccounts' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenAssetCreateBankAccountModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('record_new_asset_button_' + URLassetType)}
                        </Button>
                      )}
                      {URLassetType === 'etfs' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenAssetBuyShareModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('record_new_asset_button_' + URLassetType)}
                        </Button>
                      )}
                      {URLassetType === 'credits' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenAssetCreateCreditModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('record_new_asset_button_' + URLassetType)}
                        </Button>
                      )}
                      {URLassetType === 'shares' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenAssetBuyShareModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('record_new_asset_button_' + URLassetType)}
                        </Button>
                      )}
                      {URLassetType === 'vehicles' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenAssetCreateVehicleModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('record_new_asset_button_' + URLassetType)}
                        </Button>
                      )}
                      {URLassetType === 'realestates' && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={handleOpenAssetCreateRealEstateModal} // Add the onClick event handler here
                          disabled={isLoading}
                          style={{ textTransform: 'capitalize' }}
                        >
                          {t('record_new_asset_button_' + URLassetType)}
                        </Button>
                      )}
                    </Box>
                  </Box>
                </div>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <CardContent>
                {!isLoading &&
                  <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <Typography variant="h6" gutterBottom>
                        {t('list_of_assets')}
                      </Typography>

                      <SettingsIcon onClick={handleOpenSettingsModal} style={{ cursor: 'pointer' }} />
                    </div>
                    <Typography variant="body1" style={{ ...textStyle_small_light }}>
                      {t('total_value')}:&nbsp;
                      <span style={{
                        ...cellTextStyle,
                        ...(PieDataTotal < 0 ? loss_color : gain_color),
                      }}>
                        {PieDataTotal.toLocaleString('en-AU', { style: 'currency', currency: base_currency })}
                      </span>
                      {totalPL !== null && (
                        <>
                          <span style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            ...cellTextStyle
                          }}>|
                          </span>
                          {t('total_PL')}:&nbsp;
                          <span style={{
                            ...cellTextStyle,
                            ...(totalPL < 0 ? loss_color : gain_color),
                          }}>
                            {totalPL.toLocaleString('en-AU', { style: 'currency', currency: base_currency })}
                          </span>
                          <span style={{
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            ...cellTextStyle
                          }}>|
                          </span>
                          {t('average_lot_size')}:&nbsp;
                          <span style={{
                            ...cellTextStyle,
                          }}>
                            {(PieDataTotal / assets.length).toLocaleString('en-AU', { style: 'currency', currency: base_currency })}
                          </span>
                        </>
                      )}
                    </Typography>
                  </>
                }
                {isLoadingAssets ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                    <CircularProgress />
                  </Box>
                ) : (renderTableForCategory(URLassetType, 'value'))}
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} sm={12} style={{ marginBottom: '10px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
              <CardContent>
                <Typography variant="h6" gutterBottom>
                  Trend Chart
                </Typography>
                <FormControl fullWidth style={{ marginBottom: '20px' }}>
                  <InputLabel id="timeframe-select-label">Time Frame</InputLabel>
                  <Select
                    labelId="timeframe-select-label"
                    id="timeframe-select"
                    value={timeFrame}
                    label="Time Frame"
                    onChange={(e) => {
                      setTimeFrame(e.target.value);
                      updateChartData(e.target.value);
                    }}
                  >
                    <MenuItem value="1M">1 Month</MenuItem>
                    <MenuItem value="1Q">1 Quarter</MenuItem>
                    <MenuItem value="1Y">1 Year</MenuItem>
                    <MenuItem value="ALL">All</MenuItem>
                  </Select>
                </FormControl>
                {isLoadingTrendChart ? (
                  <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                    <CircularProgress />
                  </Box>
                ) : (
                  <Line data={chartData} options={ChartOptions} />
                )}
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} style={{ minWidth: isMobile ? '300px' : '500px' }}>
            <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
              <CardContent>
                <Carousel
                // Add any additional props or class names here
                >
                  {carouselItems.map((item, index) => (
                    <div key={`carousel-item-${index}`}>
                      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Typography variant="h6" gutterBottom>
                          {t(item.title + '_pie_title')}
                        </Typography>
                        <PieChartIcon onClick={handleOpenSettingsModal} style={{ cursor: 'pointer' }} />
                      </div>
                      {isLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                          <CircularProgress />
                        </Box>
                      ) : (
                        item.renderFunc()
                      )}
                    </div>
                  ))}
                </Carousel>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} style={{ marginBottom: '10px' }}>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
}

export default Page_Assets;
