import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Autocomplete, TextField, Dialog, DialogActions, Button, CircularProgress, DialogTitle, ThemeProvider, FormControl, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { t } from './Util_format';
import { theme } from './theme';
import config from './config/env.json';
import { fetchCreateTa } from './Util_API_calls';

function Modal_TaCreate({ onClose, isOpen, assetType, user_institutions, handleRefreshAssets }) {

  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [accountOpenDate, setAccountOpenDate] = useState(new Date()); // Set default date to current date
  const [user_ta_label, setTaLabel] = useState(''); // State for the bank account name
  const sortedCountries = [...config.SUPPORTED_COUNTRIES].sort((a, b) => a.name.localeCompare(b.name));
  const [taskCompleted, setTaskCompleted] = useState(false);
  const [userTaCountry, setuserTaCountry] = useState(null);
  const navigate = useNavigate();

  const handleProcessing = async () => {
    try {
      setErrorMessage('');
      // Validation checks
      if (!selectedInstitution || !selectedInstitution.user_institution_id) {
        setErrorMessage(t('please_select_institution'));
        return false;
      }
      if (!user_ta_label) {
        setErrorMessage(t('please_enter_account_label'));
        return false;
      }

      // Create associated bank account
      setIsProcessing(true);

      let user_ta_type = '';
      switch (assetType) {
        case 'shares':
        case 'etfs':
          user_ta_type = 'brokerage';
          break;

        default:
          console.log('ERROR: Asset type not found');
          return false;
          break;
      }

      const data = await fetchCreateTa(user_ta_label, user_ta_type, userTaCountry.name, selectedInstitution.user_institution_id);
      if (data) {
        setTaskCompleted(true);
        handleRefreshAssets();
        setSuccessMessage('COMPLETED SUCCESFULLY');
      } else {
        setErrorMessage("ERROR ON FETCHING DATA");
      }
    } catch (error) {
      console.error('Error:', error.message);
      // Here, handle the error, e.g., by setting an error state or showing a notification
    } finally {
      setIsProcessing(false); // Stop processing regardless of the outcome
    }
  };

  const details_area = {
    paddingLeft: '20px',
    width: '400px'
  };

  const handleClose = () => {
    if (!isProcessing) {
      onClose();
    }
  };

  const note = "Give a name to this account (e.g. IBRK)";
  const note3 = "All transactions will be suggested in this currency";
  const rowteStyle = { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '560px', margin: '20px', paddingRight: '20px' };
  const inputStyle = { width: '330px' };

  // Reset modal state when it opens
  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
      setSuccessMessage(null);
      setIsProcessing(false);
      setSelectedInstitution(null);
      setTaLabel(''); // Reset to empty string or initial value
      setAccountOpenDate(new Date()); // Reset to current date or use null for no selection
      setTaskCompleted(false);
    }
  }, [isOpen]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={handleClose} // Use the modified handleClose function
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t('create_new_brokerage_account')}</DialogTitle>
        {errorMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        {successMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="success-container">
              <div className="success-icon">!</div>
              <Typography className="success-text">{successMessage}</Typography>
            </div>
          </div>
        )}
        {!taskCompleted && (
          <>
            <ListItem disablePadding style={rowteStyle}>
              <Autocomplete
                options={user_institutions}
                getOptionLabel={(option) => option.user_institution_label || ''}
                renderInput={(params) => <TextField {...params} label={t('select_bank')} variant="outlined" />}
                value={selectedInstitution}
                onChange={(event, newValue) => {
                  setSelectedInstitution(newValue);
                }}
                style={inputStyle}
                disabled={isProcessing}
              />
              <span style={details_area}>
                {t('select_bank')} /
                <span
                  onClick={() => navigate('/assets/bankaccounts/create-bank')}
                  style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                >
                  {t('create_institution')}
                </span>
              </span>

            </ListItem>

            <ListItem disablePadding style={rowteStyle}>
              <TextField
                label={t('brokerage_account_label')} // Replace with appropriate text
                variant="outlined"
                value={user_ta_label}
                onChange={(e) => setTaLabel(e.target.value)}
                style={inputStyle}
                disabled={isProcessing}
              />
              <ListItemText primary={note} style={details_area} /> {/* You can replace 'note' with a new note specific to this field */}
            </ListItem>

            <ListItem disablePadding style={rowteStyle}>
              <FormControl style={inputStyle}>

                <Autocomplete
                  options={sortedCountries}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} label="Country" />}
                  value={userTaCountry}
                  onChange={(_, newValue) => setuserTaCountry(newValue)}
                  fullWidth
                  disabled={isProcessing}
                />

              </FormControl>
              <ListItemText primary={note3} style={details_area} />
            </ListItem>
          </>
        )}
        <DialogActions>
          <Button onClick={onClose} variant="contained" disabled={isProcessing}>
            {t('close')}
          </Button>
          {!taskCompleted && (
            <Button onClick={handleProcessing} variant="contained" color="primary" disabled={isProcessing}>
              {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export { Modal_TaCreate };
