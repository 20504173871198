import React, { useState } from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { t } from './Util_format';

function Modal_ReadMessage({ closeModal, onClose, msg_id, subject, content }) {
  return (
    <Dialog open={true} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle className='modal_title'>
        {subject}
      </DialogTitle>
      <DialogContent>
        {content}
        {msg_id}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => closeModal()}
          variant="contained"
          color="primary"

        > {t('close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal_ReadMessage;
