import React, { useState, useRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, CircularProgress, Grid, Typography } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import { t } from './Util_format';
import { getCurrencySymbol } from './Util_currencies';
import { NumericFormat } from 'react-number-format';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format } from 'date-fns';
import { fetchTradeSellSharesEtfs } from './Util_API_calls';

const Modal_AssetSell_shares_etfs = ({ isOpen, onClose, asset, assetType, handleRefreshAssets }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [pricePerUnit, setPricePerUnit] = useState('');
  const pricePerUnitInputRef = useRef(null);
  const [numberOfItems, setNumberOfItems] = useState('');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [errorMessage, setErrorMessage] = React.useState('');
  const [successMessage, setSuccessMessage] = React.useState('');
  const [taskCompleted, setTaskCompleted] = React.useState(false);
  const navigate = useNavigate();


  const handleChangePricePerUnit = (newValue) => {
    setPricePerUnit(newValue);
  };

  const handleChangeNumberOfItems = (newValue) => {
    setNumberOfItems(newValue);
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSell = async () => {
    setIsProcessing(true);
    const itemsToSell = Number(numberOfItems);
    const availableItems = Number(asset.dynamic_current_quantity);
    if (itemsToSell > availableItems) {
      setErrorMessage(`Error: You cannot sell more than the available shares (${availableItems}).`);
      setIsProcessing(false);
    } else {
      const data = await fetchTradeSellSharesEtfs(assetType, asset.user_asset_id, numberOfItems, pricePerUnit, format(selectedDate, 'yyyy-MM-dd'));
      if (data) {
        setTaskCompleted(true);
        setIsProcessing(false);
        handleRefreshAssets();
        setErrorMessage('');
        setSuccessMessage(t('sell_shares_recorded'));
      } else {
        setErrorMessage(t('unknown_api_error'));
        setIsProcessing(false);
      }
    }
  };

  const calculateTotal = () => {
    if (asset.user_asset_product_currency === 'GBX') {
      return Number(pricePerUnit) * Number(numberOfItems) / 100;
    } else {
      return Number(pricePerUnit) * Number(numberOfItems);
    }
  };

  const handleClose = () => {
    if (!isProcessing) {
      setTaskCompleted(false);
      setSuccessMessage(null);
      onClose();
      navigate('/assets/' + assetType);
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogTitle>{t('sell_shares')} - {asset.user_asset_label}</DialogTitle>
      <DialogContent>
        {errorMessage && (
          <div className="error-container">
            <div className="error-icon">!</div>
            <Typography className="error-text">{errorMessage}</Typography>
          </div>
        )}
        {successMessage && (
          <div className="success-container">
            <div className="success-icon">!</div>
            <Typography className="success-text">{successMessage}</Typography>
          </div>
        )}
        {!taskCompleted && (
          <>
            {t('available_quantity')}: <span
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() => setNumberOfItems(asset.dynamic_current_quantity)}
            >
              {new Intl.NumberFormat('en-US').format(asset.dynamic_current_quantity)}
            </span><br />
            {t('total_current_value')}: {asset.table_total_value}
          </>
        )}
      </DialogContent>
      {!taskCompleted && (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <NumericFormat
                value={numberOfItems}
                thousandSeparator="."
                decimalSeparator=","
                customInput={TextField}
                label={t('number_of_shares')}
                variant="outlined"
                name="numberOfItems"
                fullWidth
                decimalScale={2}
                allowNegative={false}
                onValueChange={(values) => {
                  const formattedValue = values.floatValue ? values.floatValue.toString() : '';
                  handleChangeNumberOfItems(formattedValue);
                }}
                disabled={isProcessing}
              />
            </Grid>
            <Grid item xs={6}>
              <NumericFormat
                value={pricePerUnit}
                thousandSeparator="."
                decimalSeparator=","
                suffix={" " + getCurrencySymbol(asset.user_asset_product_currency)}
                customInput={TextField}
                label={t("share_price")}
                variant="outlined"
                name="pricePerUnit"
                decimalScale={2}
                fullWidth
                allowNegative={false}
                inputRef={pricePerUnitInputRef}
                onValueChange={(values) => handleChangePricePerUnit(values.floatValue.toString())}
              />
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label={t("date_of_sale")}
                  value={selectedDate}
                  onChange={handleDateChange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      variant="outlined"
                    />
                  )}
                  maxDate={new Date()}
                />
              </LocalizationProvider>
            </Grid>


            <Grid item xs={6} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', fontWeight: 'bold' }}>TOTAL: &nbsp;
              <NumericFormat
                value={calculateTotal()}
                displayType={'text'}
                thousandSeparator="."
                decimalSeparator=","
                prefix={getCurrencySymbol(asset.user_asset_product_currency)}
                decimalScale={2}
              />
            </Grid>
          </Grid>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={handleClose} disabled={isProcessing}>
          {taskCompleted ? t('close') : t('cancel')}
        </Button>
        {!taskCompleted && (
          <Button onClick={handleSell} color="primary" variant="contained" disabled={isProcessing}>
            {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('sell_shares_record')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default Modal_AssetSell_shares_etfs;
