import React, { useState, useEffect, useRef } from 'react';
import { TextField, InputAdornment, Modal, Typography, Button, Link, CircularProgress } from '@mui/material';
import { getAuth, applyActionCode, confirmPasswordReset, checkActionCode } from "firebase/auth";
import './Auth_action.css';
import logopng from './pics/logo.png';
import { getCookieValue } from './App';
import translations from './config/translations.json';
import { useNavigate, useLocation } from 'react-router-dom';
import { t } from './Util_format';

function Auth_action() {
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isVerifying, setIsVerifying] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [newPassword, setNewPassword] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(''); // New state variable
  const navigate = useNavigate();
  const didMountRef = useRef(false);

  useEffect(() => {
    if (!didMountRef.current) {
      const urlParams = new URLSearchParams(window.location.search);
      const oobCode = urlParams.get('oobCode');

      if (!oobCode) return;

      setIsVerifying(true);

      const auth = getAuth();

      checkActionCode(auth, oobCode).then(actionCodeInfo => {
        setActionType(actionCodeInfo.operation);

        switch (actionCodeInfo.operation) {
          case "VERIFY_EMAIL":
            return applyActionCode(auth, oobCode);
          case "PASSWORD_RESET":
            // We won't handle the reset immediately since we'll show a form to the user.
            break;
          default:
            throw new Error('Unsupported action.');
        }
      }).then(() => {
        setIsVerifying(false);
        setError(null);
        if (actionType === "VERIFY_EMAIL") {
          setSuccess(t('verification_success'));
        }
      }).catch(err => {
        console.error("Error processing action:", err);
        setIsVerifying(false);
        setError(t("link_invalid_try_again"));
      });

      didMountRef.current = true;
    }
  }, []);

  const handlePasswordReset = () => {
    if (newPassword !== confirmPassword) {
      setError(t('passwords_do_not_match')); // You need to add this key to your translations
      return;
    }

    // check complexity: The password must be at least 8 characters long, contain at least one uppercase letter, and a special character such as !@#$%^&*(),`\"`\":|<>.
    if (!newPassword.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*(),`"':|<>]).{8,}$/)) {
      setError(t('password_requirements')); // You need to add this key to your translations
      return;
    }

    const auth = getAuth();
    const urlParams = new URLSearchParams(window.location.search);
    const oobCode = urlParams.get('oobCode');

    confirmPasswordReset(auth, oobCode, newPassword).then(() => {
      setSuccess(t('password_reset_success'));
      navigate("/login?message=password_reset_success");
    }).catch(err => {
      console.error("Error resetting password:", err);
      switch (err.code) {
        case "auth/weak-password":
          setError(t("password_requirements"));
          break;
        case "auth/expired-action-code":
          setError(t("reset_code_expired"));
          break;

        default:
          setError(t("error_resetting_password_try_again"));
      }
    });
  };
  return (
    <div id="page_auth_actions">
      <div className="container">
        <div className="unauthenticated_left_container">
          <img src={logopng} alt="Logo" className="login_logo_desktop" />
          <div className="overlayImage"></div>
        </div>

        <div className="unauthenticated_right_container">
          {/* Conditional rendering of titles based on actionType */}
          {actionType === "VERIFY_EMAIL" && (
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {t("email_verification_outcome")}
            </Typography>
          )}
          {actionType === "PASSWORD_RESET" && (
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {t("set_new_password_title")}
            </Typography>
          )}
          {(!actionType || actionType === "UNKNOWN") && (
            <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
              {t("registration_outcome")}
            </Typography>
          )}
          {actionType === "VERIFY_EMAIL" && (
            <Typography variant="body2" gutterBottom>
              {t("already_have_account")} <Link href="/login" underline="none" id="creaAccountLink">{t('login')}</Link>
            </Typography>
          )}
          {isVerifying && <Typography>{t("loading")}</Typography>}
          {error && (
            <>
              <div className="error-container">
                <div className="error-icon">!</div>
                <Typography className="error-text">{t(error)}</Typography>
              </div>
            </>
          )}


          {success &&
            <div className="success-container">
              <div className="success-icon">✓</div>
              <Typography className="success-text">{success}</Typography>
            </div>
          }
          <div className="center-container">
            <Link href="/login?action=clean-cookie" variant="body2" underline="none">
              {t("back_to_login")}
            </Link>
          </div>
          
          {actionType === "PASSWORD_RESET" && !success && (
            <div>
              <TextField
                type="password"
                margin="normal"
                fullWidth
                variant="outlined"
                label={t('enter_new_password')}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <TextField
                type="password"
                margin="normal"
                fullWidth
                variant="outlined"
                label={t('confirm_new_password')} // You need to add this key to your translations
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <Button
                variant="contained"
                color="primary"
                className="unauthenticated_button"
                sx={{
                  mt: 2,
                  borderRadius: '1rem',
                  textTransform: 'none',
                  backgroundColor: isProcessing ? '#AAAAAA !important' : undefined,
                  color: isProcessing ? '#FFFFFF !important' : undefined
                }}
                onClick={handlePasswordReset}
                disabled={isProcessing}
              >
                {isProcessing ? <CircularProgress size={24} color="inherit" /> : t("reset_password")}
              </Button>

            </div>
          )}
        </div>
      </div>
    </div>
  );

}

export default Auth_action;
