import React, { useState } from 'react';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { getAuth, createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { getCookieValue, deleteCookie } from './App';  // assuming the component is in the same directory as App.js
import { TextField, InputAdornment, Modal, Typography, Button, Link, CircularProgress } from '@mui/material';
import { useEffect } from 'react';
import translations from './config/translations.json';
import './Unauthenticated.css';
import logopng from './pics/logo.png';
import { t } from './Util_format';

function Register() {
  const [nome, setNome] = useState("");
  const [cognome, setCognome] = useState("");
  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null); // To handle login errors
  const navigate = useNavigate();
  const [isProcessing, setIsProcessing] = useState(false);


  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleTogglePassword = () => setShowPassword(!showPassword);
  const [passwordConfirm, setPasswordConfirm] = useState("");

  const [htmlContent, setHtmlContent] = useState(""); // <-- Add this line for the HTML content


  const isValidPassword = (password) => {
    // Password length check
    if (password.length < 8) return false;

    // Check for uppercase
    if (!/[A-Z]/.test(password)) return false;

    // Check for special character
    if (!/[!@#$%^&*(),`"`":|<>]/.test(password)) return false;

    return true;
  };

  const handleRegister = async () => {
    setIsProcessing(true);
    deleteCookie();
    const auth = getAuth();

    // Check if name and surname are present
    if (!nome.trim() || !cognome.trim()) {
      setError(t('please_type_name_and_surname')); // please_type_name_and_surname
      setIsProcessing(false);
      return;
    }

    if (password !== passwordConfirm) {
      setError(t('passwords_do_not_match')); // please_type_name_and_surname
      setIsProcessing(false);
      return;
    }

    // Check for password complexity
    if (!isValidPassword(password)) {      
      setError(t('password_requirements')); // please_type_name_and_surname
      setIsProcessing(false);
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Send verification email
      await sendEmailVerification(user);

      // Inform the user that the verification email was sent
      navigate("/login?message=registration_completed_check_your_email");
    } catch (registrationError) {
      switch (registrationError.message) {
        case 'Firebase: Error (auth/email-already-in-use).':
          setError(t("email_not_accepted"));
          break;
        default:
          setError(registrationError.message || "An error occurred.");
          break;
      }      

    }

    setIsProcessing(false);
  };


  useEffect(() => {
    // Check for the 'action=clean-cookie' in the URL
    const cleanCookieAction = new URLSearchParams(window.location.search).get('action') === 'clean-cookie';

    if (cleanCookieAction) {
      // If the action is to clean the cookie, delete it
      deleteCookie();
    } else {
      // Otherwise, check for the existence of the firebaseToken
      const token = getCookieValue('firebaseToken');
      const loginOtpCompleted = getCookieValue('login_otp_completed');
      if (token) {
        if (loginOtpCompleted === 'true') {
          navigate("/home");
        } else
          navigate("/login_otp");
      }
    }
  }, [navigate]);


  useEffect(() => {
    fetch('./docs/terms.html') // Adjust the path if necessary
      .then(response => response.text())
      .then(content => {
        setHtmlContent(content);
      });
  }, [navigate]);

  return (
    <div id="page_register">
      <div className="container">
        <div className="unauthenticated_left_container">
          <img src={logopng} alt="Logo" className="login_logo_desktop" />
          <div className="overlayImage"></div>
        </div>

        <div className="unauthenticated_right_container">
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {t('create_account')}
          </Typography>

          <Typography variant="body2" gutterBottom>
            {t("already_have_account")} <Link href="/login" underline="none" id="creaAccountLink">{t('login')}</Link>
          </Typography>

          <div className="nameContainer" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <TextField
              label={t('first_name')}
              variant="outlined"
              margin="normal"
              value={nome}
              onChange={(e) => setNome(e.target.value)}
              sx={{ borderRadius: '1rem', width: '49%' }}
              className="input_login"
            />

            <TextField
              label={t('last_name')}
              variant="outlined"
              margin="normal"
              value={cognome}
              onChange={(e) => setCognome(e.target.value)}
              sx={{ borderRadius: '1rem', width: '49%' }}
              className="input_login"
            />
          </div>

          <TextField
            label={t('email_address')}
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ borderRadius: '1rem' }}
            className="input_login"
          />

          <TextField
            label={t('password')}
            variant="outlined"
            fullWidth
            margin="normal"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ borderRadius: '1rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showPassword ? (
                    <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                  ) : (
                    <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                  )}
                </InputAdornment>
              )
            }}
            className="input_login"
          />

          <TextField
            label={t('confirm_password')}
            variant="outlined"
            fullWidth
            margin="normal"
            type={showPassword ? "text" : "password"}
            value={passwordConfirm}
            onChange={(e) => setPasswordConfirm(e.target.value)}
            sx={{ borderRadius: '1rem' }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {showPassword ? (
                    <Visibility onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                  ) : (
                    <VisibilityOff onClick={handleTogglePassword} style={{ cursor: "pointer" }} />
                  )}
                </InputAdornment>
              )
            }}
            className="input_login"
          />

          {error &&
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{error}</Typography>
            </div>
          }

          <Button
            variant="contained"
            color="primary"
            className="unauthenticated_button"
            sx={{
              borderRadius: '1rem',
              textTransform: 'none',
              backgroundColor: isProcessing ? '#AAAAAA !important' : undefined,
              color: isProcessing ? '#FFFFFF !important' : undefined
            }}
            onClick={handleRegister}
            disabled={isProcessing}
          >
            {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('create_account')}
          </Button>

          <Typography variant="body2" className="terms">
            <Link href="#" className="terminicondizioni" onClick={handleOpen}>
              {t('terms_and_conditions_use')}
            </Link>
          </Typography>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
            className="custom-modal"
          >
            <div className="modal-content">
              <Typography id="modal-title" variant="h6" component="h2" className="modal-title">
                {t('terms_and_conditions')}
              </Typography>
              <Typography id="modal-description" className="modal-description">
                <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                <Button variant="contained" className="unauthenticated_button" onClick={handleClose}>{t('close')}</Button>
              </Typography>
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );

}

export default Register;