import { createTheme } from '@mui/material';

export const PIE_COLORS = [
  "#82E0AA", // Soft Green
  "#85C1E9", // Soft Blue
  "#A569BD", // Soft Purple
  "#F7DC6F", // Soft Gold
  "#76D7C4", // Soft Teal
  "#F0B27A", // Soft Apricot
  "#D7DBDD", // Soft Gray
  "#EBDEF0", // Soft Lavender
  "#CD6155", // Soft Brick Red
  "#F5B041", // Soft Amber
  "#AED6F1", // Soft Sky Blue
  "#A3E4D7", // Soft Mint
  "#FADBD8", // Soft Pink
  "#D5DBDB", // Soft Silver
  "#F5CBA7", // Soft Peach
  "#A9DFBF", // Soft Sage
  "#D2B4DE", // Soft Mauve
  "#FFF79A", // Soft Yellow
  "#F7C48B", // Soft Orange
  "#F78F8F"  // Soft Red
];    

export const theme = createTheme({
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        .carousel-nav-button {
          opacity: 1 !important; // Override default behavior
          // Add other styles as needed
        }
      `,
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#FFFFFF',
          borderRight: '1px dotted #E0E0E0',
          width: 240,
          '&.reduced-drawer': {
            width: 80,
          },
          padding: '10px',
        },
        paperAnchorRight: {
          width: '350px',
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: 'rgba(FF, 0, 0, 0.08)',
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Existing styles for all buttons
        },
      },
    },   
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#f7f7f7',
          boxShadow: 'none',
          marginLeft: '240px', // Adjust accordingly
          padding: '7px',
          height: '80px',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#34403A',
          fontSize: '1rem',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          fontSize: '1rem',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#2a9461',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.87)',
    },
  },
  background: {
    default: '#FAFAFA',
    paper: '#FAFAFA',
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    body1: {
      fontSize: '1rem',
    },
  },
});
