import React, { useEffect, useState, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography, Select, MenuItem, FormControl, InputLabel, ThemeProvider, TextField, Box } from '@mui/material';
import { t } from './Util_format';
import { theme } from './theme';
import { fetchRenameAsset, fetchUpdateAssetBalance, fetchUpdateAssetEstimatedValue, fetchUpdateTa } from './Util_API_calls';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import EditIcon from '@mui/icons-material/Edit';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'; // For deposit
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward'; // For withdraw
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { NumericFormat } from 'react-number-format';
import ListIcon from '@mui/icons-material/List';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';

function Modal_AssetsActions({ onClose, isOpen, assetType, asset, trading_accounts, handleRefreshAssets }) {
  const [isProcessing, setIsProcessing] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const [renameMode, setRenameMode] = useState(false);
  const [updateBalanceMode, setUpdateBalanceMode] = useState(false);
  const [updateEstimatedValueMode, setupdateEstimatedValueMode] = useState(false);
  const [newAssetName, setNewAssetName] = useState('');
  const [newAssetBalance, setNewAssetBalance] = useState('');
  const [newAssetEstimatedValue, setNewAssetEstimatedValue] = useState('');
  const [ModalAssetLabel, setModalAssetLabel] = useState('');
  const [updateTaMode, setUpdateTaMode] = useState(false);
  const [newTaID, setNewTaID] = useState('');
  const balanceInputRef = useRef(null);
  const estimatedValueInputRef = useRef(null);

  const handleCancelUpdateBalance = () => {
    setErrorMessage('');
    setUpdateBalanceMode(false);
  };

  const handleCancelUpdateEstimatedValue = () => {
    setErrorMessage('');
    setupdateEstimatedValueMode(false);
  };

  const handleUpdateBalanceClick = () => {
    setErrorMessage('');
    setUpdateBalanceMode(true);
    setRenameMode(false);
    setNewAssetBalance(newAssetBalance || asset.dynamic_balance[asset.user_asset_currency]);
  };

  const handleUpdateEstimatedValueClick = () => {
    setErrorMessage('');
    setupdateEstimatedValueMode(true);
    setRenameMode(false);
    setNewAssetEstimatedValue(newAssetEstimatedValue || asset.product_last_value[asset.user_asset_product_currency]);
  };
  const handleSaveNewBalance = async () => {
    setErrorMessage('');
    setIsProcessing(true); // Start processing

    if (asset && asset.user_asset_id) {
      try {
        const data = await fetchUpdateAssetBalance(assetType, asset.user_asset_id, newAssetBalance);
        // Update the asset's balance in the local state
        asset.dynamic_balance[asset.user_asset_currency] = newAssetBalance;
      } catch (error) {
        console.error('Error updating asset balance', error);
      }
    } else {
      console.error('Asset ID is missing');
    }
    handleRefreshAssets();
    setIsProcessing(false); // End processing
    setUpdateBalanceMode(false);
  };


  const handleSaveNewEstimatedValue = async () => {
    setErrorMessage('');
    setErrorMessage('');
    setIsProcessing(true); // Start processing
    // console.log('New asset balance:', newAssetBalance);

    if (asset && asset.user_asset_id) {
      try {
        const data = await fetchUpdateAssetEstimatedValue(assetType, asset.user_asset_id, newAssetEstimatedValue);
        // Update the label or any other state if necessary
        // setModalAssetLabel(newAssetBalance); // Only if you need to update the label
      } catch (error) {
        console.error('Error updating asset balance', error);
      }
    } else {
      console.error('Asset ID is missing');
    }
    handleRefreshAssets();
    setIsProcessing(false); // End processing
    setUpdateBalanceMode(false);
  };

  const handleRenameClick = () => {
    setErrorMessage('');
    setRenameMode(true);
    setUpdateBalanceMode(false);
    setupdateEstimatedValueMode(false);
    setUpdateTaMode(false);
    setNewAssetName(newAssetName || asset.user_asset_label);
  };

  const handleUpdateTaClick = () => {
    setErrorMessage('');
    setUpdateTaMode(true);
    setRenameMode(false);
    setUpdateBalanceMode(false);
    setupdateEstimatedValueMode(false);
    setNewTaID(newTaID || asset.user_ta_id);
  }

  const handleCancelRename = () => {
    setErrorMessage('');
    setRenameMode(false);
    setUpdateBalanceMode(false);
    setupdateEstimatedValueMode(false);
    setUpdateTaMode(false);
  };

  const handleCancelUpdateTa = () => {
    setErrorMessage('');
    setRenameMode(false);
    setUpdateBalanceMode(false);
    setupdateEstimatedValueMode(false);
    setUpdateTaMode(false);
  };

  const navigate = useNavigate();

  const handleOpenBuyMoreSharesModal = () => {
    navigate('/assets/' + assetType + '/buy/' + asset.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenBuyMoreEtfsModal = () => {
    navigate('/assets/' + assetType + '/buy/' + asset.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenSellSharesModal = () => {
    navigate('/assets/' + assetType + '/sell/' + asset.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenSellEtfsModal = () => {
    navigate('/assets/' + assetType + '/sell/' + asset.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenSellCommoditiesModal = () => {
    navigate('/assets/' + assetType + '/sell/' + asset.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenBuyMoreCommoditiesModal = () => {
    navigate('/assets/' + assetType + '/buy/' + asset.user_asset_id); // Navigate to the desired URL
    onClose(); // Close the current modal
  };

  const handleOpenDeleteAssetBankAccountModal = () => {
    if (asset.dynamic_balance.USD !== 0) {
      setErrorMessage(t('cannot_close_asset_with_balance'));
      return null;
    } else {
      navigate('/assets/' + assetType + '/delete/' + asset.user_asset_id); // Navigate to the desired URL
      onClose(); // Close the current modal
    }
  };

  const handleOpenDeleteAssetCreditsModal = () => {
    if (parseFloat(asset.dynamic_balance[asset.user_asset_currency]) !== 0) {
      setErrorMessage(t('cannot_close_asset_with_balance'));
      return null;
    } else {
      navigate('/assets/' + assetType + '/delete/' + asset.user_asset_id); // Navigate to the desired URL
      onClose(); // Close the current modal
    }
  };

  const handleOpenDeleteAssetVehiclesModal = () => {
    if (asset.product_last_value.USD !== 0) {
      setErrorMessage(t('cannot_remove_vehicle_with_value_update_it_to_zero'));
      return null;
    } else {
      navigate('/assets/' + assetType + '/delete/' + asset.user_asset_id); // Navigate to the desired URL
      onClose(); // Close the current modal
    }
  };

  const handleOpenDeleteRealEstatesModal = () => {
    if (asset.product_last_value.USD !== 0) {
      setErrorMessage(t('cannot_remove_realestate_with_value_update_it_to_zero'));
      return null;
    } else {
      navigate('/assets/' + assetType + '/delete/' + asset.user_asset_id); // Navigate to the desired URL
      onClose(); // Close the current modal
    }
  };

  const handleSaveNewName = async () => {
    setIsProcessing(true); // Start processing
    // console.log('New asset name:', newAssetName);

    if (asset && asset.user_asset_id) {
      try {
        const data = await fetchRenameAsset(assetType, asset.user_asset_id, newAssetName);
        setModalAssetLabel(newAssetName);
      } catch (error) {
        console.error('Error renaming asset', error);
      }
    } else {
      console.error('Asset ID is missing');
    }
    handleRefreshAssets();
    setIsProcessing(false); // End processing
    setRenameMode(false);
  };

  const handleSaveTa = async () => {
    setIsProcessing(true); // Start processing
    // console.log('New TA name:', newTaID);

    if (asset && asset.user_asset_id) {
      try {
        const data = await fetchUpdateTa(assetType, asset.user_asset_id, newTaID);
        setNewTaID(newTaID);
      } catch (error) {
        console.error('Error renaming asset', error);
      }
    } else {
      console.error('Asset ID is missing');
    }
    handleRefreshAssets();
    setIsProcessing(false); // End processing
    setUpdateTaMode(false);
  }

  const openWithdrawMoneyModal = () => setRenameMode(false);
  const openDepositMoneyModal = () => setRenameMode(false);

  const closeActionModal = () => {
    if (!isProcessing) {
      onClose();
      navigate(`/assets/${assetType}`);
    }
  };

  // Update ModalAssetLabel whenever the asset prop changes
  useEffect(() => {
    if (isOpen && asset) {
      setModalAssetLabel(asset.user_asset_label || 'Unknown');
    }
    if (!isOpen) {
      setIsProcessing(false);
      setErrorMessage('');
      setRenameMode(false);
      setUpdateTaMode(false); // Ensure this state is reset
      setUpdateBalanceMode(false);
      setupdateEstimatedValueMode(false); // Ensure this state is reset
      setNewAssetName('');
      setNewAssetBalance('');
      setNewAssetEstimatedValue(''); // Ensure this state is reset
      setNewTaID(''); // Ensure this state is reset
    }
  }, [isOpen, asset]);


  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={isProcessing ? null : onClose} // Disable onClose callback when processing
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown={isProcessing} // Disable escape keydown when processing
      >
        <DialogTitle className='modal_title'>
          {ModalAssetLabel}<br></br>
        </DialogTitle>
        {errorMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        <DialogContent>
          <List>
            {(assetType === 'bankaccounts' || assetType === 'commodities' || assetType === 'credits' || assetType === 'vehicles' || assetType === 'realestates') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {renameMode ? (
                  <>
                    <TextField
                      label={t("asset_name")}
                      variant="outlined"
                      value={newAssetName}
                      onChange={(e) => setNewAssetName(e.target.value)}
                      style={{ flexGrow: 1, marginRight: '10px', width: '50%' }}
                      disabled={isProcessing}
                    />
                    <Button
                      onClick={handleCancelRename}
                      variant="outlined"
                      style={{ flexGrow: 1, maxWidth: '25%', marginRight: '10px' }}
                      disabled={isProcessing}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSaveNewName}
                      variant="contained"
                      color="primary"
                      style={{ flexGrow: 1, maxWidth: '25%' }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("save")
                      )}
                    </Button>
                  </>
                ) : (
                  <ListItemButton onClick={handleRenameClick} disabled={isProcessing}>
                    <ListItemText primary={t('rename_asset')} />
                    <EditIcon />
                  </ListItemButton>
                )}
              </ListItem>
            )}
            {(assetType === 'bankaccounts' || assetType === 'credits') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {updateBalanceMode ? (
                  <>
                    <NumericFormat
                      value={newAssetBalance}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      customInput={TextField}
                      label={t('new_balance')}
                      variant="outlined"
                      name="new_balance"
                      decimalScale={2}
                      fullWidth
                      inputRef={balanceInputRef}
                      allowNegative={false}
                      onValueChange={(values) => {
                        const { value } = values;
                        setNewAssetBalance(value); // Correctly update the newAssetBalance
                      }}
                      style={{ flexGrow: 1, marginRight: '10px', width: '50%' }}
                    />
                    <Button
                      onClick={handleCancelUpdateBalance}
                      variant="outlined"
                      style={{ flexGrow: 1, maxWidth: '25%', marginRight: '10px' }}
                      disabled={isProcessing}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      onClick={handleSaveNewBalance}
                      variant="contained"
                      color="primary"
                      style={{ flexGrow: 1, maxWidth: '25%' }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("save")
                      )}
                    </Button>
                  </>
                ) : (
                  <ListItemButton onClick={handleUpdateBalanceClick} disabled={isProcessing}>
                    <ListItemText primary={t('update_balance')} />
                    <EditIcon />
                  </ListItemButton>
                )}
              </ListItem>
            )}
            {(assetType === 'vehicles' || assetType === 'realestates') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {updateEstimatedValueMode ? (
                  <>
                    <NumericFormat
                      value={newAssetEstimatedValue}
                      thousandSeparator={'.'}
                      decimalSeparator={','}
                      customInput={TextField}
                      label={t('new_market_value')}
                      variant="outlined"
                      name="new_estimated_value"
                      decimalScale={2}
                      fullWidth
                      inputRef={estimatedValueInputRef}
                      allowNegative={false}
                      onValueChange={(values) => {
                        const { value } = values;
                        setNewAssetEstimatedValue(value);
                      }}
                      style={{ flexGrow: 1, marginRight: '10px', width: '50%' }}
                    />
                    <Button
                      onClick={handleCancelUpdateEstimatedValue}
                      variant="outlined"
                      style={{ flexGrow: 1, maxWidth: '25%', marginRight: '10px' }}
                      disabled={isProcessing}
                    >
                      {t('cancel')}
                    </Button>
                    <Button
                      onClick={handleSaveNewEstimatedValue}
                      variant="contained"
                      color="primary"
                      style={{ flexGrow: 1, maxWidth: '25%' }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("save")
                      )}
                    </Button>
                  </>
                ) : (
                  <ListItemButton onClick={handleUpdateEstimatedValueClick} disabled={isProcessing}>
                    <ListItemText primary={t('update_market_value')} />
                    <EditIcon />
                  </ListItemButton>
                )}
              </ListItem>
            )}
            {(assetType === 'shares' || assetType === 'etfs' || assetType === 'commodities') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                {updateTaMode ? (
                  <>
                    <FormControl style={{ marginRight: '10px', width: '250px' }}>
                      <InputLabel id="account-select-label" className="assets_input-label">
                        {t('select_brokerage_account')}
                      </InputLabel>
                      <Select
                        labelId="account-select-label"
                        id="account-select"
                        value={newTaID} // Using newTaID as the state variable for the selected value
                        label={t('select_asset_type')}
                        onChange={(e) => setNewTaID(e.target.value)} // Assuming setNewTaID is the setter function for newTaID
                        color="primary"
                        IconComponent={ListIcon} // Ensure ListIcon is imported or defined
                        renderValue={(selected) => (
                          <Box display="flex" alignItems="center">
                            <Typography variant="body2" noWrap style={{ marginLeft: '8px' }}>
                              {trading_accounts.find(account => account.user_ta_id === selected)?.user_ta_label || ''}
                            </Typography>
                          </Box>
                        )}
                      >
                        {trading_accounts.map((account) => (
                          <MenuItem key={`${account.user_ta_id}-${Math.random()}`} value={account.user_ta_id}>
                            <Typography variant="body1">
                              {account.user_ta_label}
                            </Typography>
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>

                    <Button
                      onClick={handleCancelUpdateTa}
                      variant="outlined"
                      style={{ flexGrow: 1, maxWidth: '25%', marginRight: '10px' }}
                      disabled={isProcessing}
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={handleSaveTa}
                      variant="contained"
                      color="primary"
                      style={{ flexGrow: 1, maxWidth: '25%' }}
                      disabled={isProcessing}
                    >
                      {isProcessing ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        t("save")
                      )}
                    </Button>
                  </>
                ) : (
                  <ListItemButton onClick={handleUpdateTaClick} disabled={isProcessing}>
                    <ListItemText primary={t('change_ta_' + assetType)} />
                    <EditIcon />
                  </ListItemButton>
                )}
              </ListItem>
            )}
            {(assetType === 'commodities') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <ListItemButton onClick={handleOpenBuyMoreCommoditiesModal} disabled={isProcessing}>
                  <ListItemText primary={t('buy_more_items')} />
                  <AddShoppingCartIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'shares') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <ListItemButton onClick={handleOpenBuyMoreSharesModal} disabled={isProcessing}>
                  <ListItemText primary={t('buy_more_items')} />
                  <AddShoppingCartIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'etfs') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <ListItemButton onClick={handleOpenBuyMoreEtfsModal} disabled={isProcessing}>
                  <ListItemText primary={t('buy_more_items')} />
                  <AddShoppingCartIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'bankaccounts') && (
              <ListItem disablePadding>
                <ListItemButton onClick={openWithdrawMoneyModal} disabled={isProcessing}>
                  <ListItemText primary={t('withdraw')} />
                  <ArrowDownwardIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'shares') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <ListItemButton onClick={handleOpenSellSharesModal} disabled={isProcessing}>
                  <ListItemText primary={t('sell_items')} />
                  <MonetizationOnIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'etfs') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <ListItemButton onClick={handleOpenSellEtfsModal} disabled={isProcessing}>
                  <ListItemText primary={t('sell_items')} />
                  <MonetizationOnIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'commodities') && (
              <ListItem disablePadding style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <ListItemButton onClick={handleOpenSellCommoditiesModal} disabled={isProcessing}>
                  <ListItemText primary={t('sell_items')} />
                  <MonetizationOnIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'bankaccounts') && (
              <ListItem disablePadding>
                <ListItemButton onClick={openDepositMoneyModal} disabled={isProcessing}>
                  <ListItemText primary={t('deposit')} />
                  <ArrowUpwardIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'bankaccounts') && (
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenDeleteAssetBankAccountModal} disabled={isProcessing}>
                  <ListItemText primary={t('close_asset_bankaccount')} />
                  <DeleteForeverIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'credits') && (
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenDeleteAssetCreditsModal} disabled={isProcessing}>
                  <ListItemText primary={t('close_asset_credit')} />
                  <DeleteForeverIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'vehicles') && (
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenDeleteAssetVehiclesModal} disabled={isProcessing}>
                  <ListItemText primary={t('dispose_vehicle')} />
                  <DeleteForeverIcon />
                </ListItemButton>
              </ListItem>
            )}
            {(assetType === 'realestates') && (
              <ListItem disablePadding>
                <ListItemButton onClick={handleOpenDeleteRealEstatesModal} disabled={isProcessing}>
                  <ListItemText primary={t('dispose_realestate')} />
                  <DeleteForeverIcon />
                </ListItemButton>
              </ListItem>
            )}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{
              backgroundColor: 'gray',
              color: 'white',
              '&:hover': {
                backgroundColor: 'darkgray',
              },
            }}
            onClick={closeActionModal}
            variant="contained"
            disabled={isProcessing}
          >
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default Modal_AssetsActions;
