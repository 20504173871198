import React, { useState } from 'react';
import { TextField, Typography, Button, Link, CircularProgress } from '@mui/material';
import { sendPasswordResetEmail, getAuth } from "firebase/auth";
import { getCookieValue } from './App';
import './Unauthenticated.css';
import logopng from './pics/logo.png';
import translations from './config/translations.json';
import { deleteCookie } from './App';
import { t } from './Util_format';

function ResetPasswordRequest() {
  const [email, setEmail] = useState("");
  const [error, setError] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  const handleResetPassword = async () => {
    setIsProcessing(true);
    setSuccessMessage();
    setError();
    deleteCookie();
    // Check if name and  are present
    if (!email.trim()) {
      setError(t("please_insert_email"));
      setIsProcessing(false);
      return;
    }

    const auth = getAuth();

    try {
      await sendPasswordResetEmail(auth, email);
    } catch (err) {
      setError(err.message);
      if (err.message === "Firebase: Error (auth/too-many-requests).") {
        setError(t("too_many_requests_try_later"));
      } else {
        setError(err.message);
      }
    } finally {
      setIsProcessing(false);
    }
    setSuccessMessage(t("reset_password_email_sent"));
  };

  return (
    <div id="page_reset_password_form">
      <div className="container">
        <div className="unauthenticated_left_container">
          <img src={logopng} alt={t("logo")} className="login_logo_desktop" />
          <div className="overlayImage"></div>
        </div>

        <div className="unauthenticated_right_container">
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            {t("password_reset_title")}
          </Typography>
          <Typography variant="body2" gutterBottom style={{ textAlign: 'center' }}>
            {t("password_reset_description")}
          </Typography>


          <TextField
            label={t("email_address")}
            variant="outlined"
            fullWidth
            margin="normal"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            sx={{ borderRadius: '1rem' }}
            className="input_login"
          />

          {error &&
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{error}</Typography>
            </div>
          }

          {successMessage &&
            <div className="success-container">
              <div className="success-icon">✓</div>
              <Typography className="success-text">{successMessage}</Typography>
            </div>
          }

          <Button
            variant="contained"
            color="primary"
            className="unauthenticated_button"
            sx={{
              borderRadius: '1rem',
              textTransform: 'none',
              backgroundColor: isProcessing ? '#AAAAAA !important' : undefined,
              color: isProcessing ? '#FFFFFF !important' : undefined
            }}
            onClick={handleResetPassword}
            disabled={isProcessing}
          >
            {isProcessing ? <CircularProgress size={24} color="inherit" /> : t("send_reset_email")}
          </Button>

          <Typography variant="body2" className="backToLogin">
            <Link href="/login" className="backToLoginLink">
              {t("back_to_login")}
            </Link>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default ResetPasswordRequest;
