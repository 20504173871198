import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Autocomplete, TextField, Dialog, DialogActions, Button, CircularProgress, DialogTitle, ThemeProvider, Select, MenuItem, InputLabel, FormControl, Typography } from '@mui/material';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { t } from './Util_format';
import { theme } from './theme';
import { NumericFormat } from 'react-number-format';
import config from './config/env.json';
import { fetchCreateAssetsBankAccount } from './Util_API_calls';

function Modal_AssetCreate_bankaccount({ onClose, isOpen, assetType, user_institutions, handleRefreshAssets }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedInstitution, setSelectedInstitution] = useState(null);
  const [accountOpenDate, setAccountOpenDate] = useState(new Date()); // Set default date to current date
  const [user_asset_currency, setuser_asset_currency] = useState('');
  const [user_asset_balance, setuser_asset_balance] = useState('');
  const [user_asset_label, setBankAccountLabel] = useState(''); // State for the bank account name
  const sortedCurrencies = [...config.SUPPORTED_CURRENCIES].sort((a, b) => a.name.localeCompare(b.name));
  const [taskCompleted, setTaskCompleted] = useState(false);
  const navigate = useNavigate();

  const handleProcessing = async () => {
    try {
      setErrorMessage('');
      // Validation checks
      if (!selectedInstitution || !selectedInstitution.user_institution_id) {
        setErrorMessage(t('please_select_institution'));
        return false;
      }
      if (!user_asset_label) {
        setErrorMessage(t('please_enter_account_label'));
        return false;
      }
      if (!user_asset_balance) {
        setErrorMessage(t('please_enter_opening_balance'));
        return false;
      }
      if (!user_asset_currency) {
        setErrorMessage(t('please_select_the_currency'));
        return false;
      }
      if (!accountOpenDate) {
        setErrorMessage(t('please_select_the_opening_date'));
        return false;
      }

      setIsProcessing(true); // Start processing
      const data = await fetchCreateAssetsBankAccount(user_asset_label, user_asset_balance, user_asset_currency, selectedInstitution.user_institution_id, accountOpenDate.toISOString().split('T')[0]);
      if (data) {
        setTaskCompleted(true);
        handleRefreshAssets();
        setSuccessMessage('COMPLETED SUCCESFULLY');
      } else {
        setErrorMessage("ERROR ON FETCHING DATA");
      }
    } catch (error) {
      console.error('Error:', error.message);
    } finally {
      setIsProcessing(false); // Stop processing regardless of the outcome
    }
  };

  const details_area = {
    paddingLeft: '20px',
    width: '40%'  // Adjust width to ensure space is balanced
  };

  const handleClose = () => {
    if (!isProcessing) {
      onClose();
    }
  };

  const rowteStyle = { display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%', padding: '20px' };
  const inputStyle = { width: '40%' };

  // Reset modal state when it opens
  useEffect(() => {
    if (isOpen) {
      setErrorMessage('');
      setSuccessMessage(null);
      setIsProcessing(false);
      setSelectedInstitution(null);
      setBankAccountLabel(''); // Reset to empty string or initial value
      setAccountOpenDate(new Date()); // Reset to current date or use null for no selection
      setuser_asset_currency(''); // Reset to empty string or initial value
      setuser_asset_balance(''); // Reset to empty string or initial value
      setTaskCompleted(false);
    }
  }, [isOpen]);

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={isOpen}
        onClose={handleClose} // Use the modified handleClose function
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>{t('create_new_asset_title_' + assetType)}</DialogTitle>
        {errorMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="error-container">
              <div className="error-icon">!</div>
              <Typography className="error-text">{errorMessage}</Typography>
            </div>
          </div>
        )}
        {successMessage && (
          <div style={{ paddingLeft: '20px', paddingRight: '20px' }}>
            <div className="success-container">
              <div className="success-icon">!</div>
              <Typography className="success-text">{successMessage}</Typography>
            </div>
          </div>
        )}
        {!taskCompleted && (
          <>
            <ListItem disablePadding style={rowteStyle}>
              <Autocomplete
                options={user_institutions}
                getOptionLabel={(option) => option.user_institution_label || ''}
                renderInput={(params) => <TextField {...params} label={t('select_bank')} variant="outlined" />}
                value={selectedInstitution}
                onChange={(event, newValue) => {
                  setSelectedInstitution(newValue);
                }}
                style={inputStyle}
              />
              <ListItemText
                primary={
                  <span style={details_area}>
                    {t('select_bank')} /
                    <span
                      onClick={() => navigate('/assets/' + assetType + '/create-bank')}
                      style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}
                    >
                      {t('create_institution')}
                    </span>
                  </span>
                }
              />
            </ListItem>

            <ListItem disablePadding style={rowteStyle}>
              <TextField
                label={t('account_label')}
                variant="outlined"
                value={user_asset_label}
                onChange={(e) => setBankAccountLabel(e.target.value)}
                style={inputStyle}
              />
              <ListItemText primary={t('give_a_name_to_this_account')} style={details_area} />
            </ListItem>

            <ListItem disablePadding style={rowteStyle}>
              <NumericFormat
                customInput={TextField}
                label={t('opening_balance')}
                variant="outlined"
                decimalScale={2}
                allowNegative={false}
                thousandSeparator={'.'}
                decimalSeparator={','}
                onValueChange={(values) => {
                  const { value } = values;
                  setuser_asset_balance(value);
                }}
                style={inputStyle}
              />
              <ListItemText primary={t('balance')} style={details_area} />
            </ListItem>

            <ListItem disablePadding style={rowteStyle}>
              <FormControl style={inputStyle}>
                <InputLabel id="currency-select-label">{t('currency')}</InputLabel>
                <Select
                  labelId="currency-select-label"
                  id="currency-select"
                  value={user_asset_currency}
                  label={t('currency')}
                  onChange={(event) => setuser_asset_currency(event.target.value)}
                >
                  {sortedCurrencies.map((currency) => (
                    <MenuItem key={currency.currency} value={currency.currency}>
                      {`${currency.name}`}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <ListItemText primary={t('transactions_recorded_in_this_currency')} style={details_area} />
            </ListItem>

            <ListItem disablePadding style={rowteStyle}>
              <LocalizationProvider dateAdapter={AdapterDateFns} style={inputStyle}>
                <DatePicker
                  label={t('opening_date')}
                  value={accountOpenDate}
                  onChange={(newValue) => {
                    setAccountOpenDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  inputFormat="dd/MM/yyyy"
                  maxDate={new Date(Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate()))}
                />
              </LocalizationProvider>
              <ListItemText primary={t('data_tracked_from_this_day_onwards')} style={details_area} />
            </ListItem>
          </>
        )}
        <DialogActions>
          <Button onClick={onClose} variant="contained" disabled={isProcessing}>
            {t('close')}
          </Button>
          {!taskCompleted && (
            <Button onClick={handleProcessing} variant="contained" color="primary" disabled={isProcessing}>
              {isProcessing ? <CircularProgress size={24} color="inherit" /> : t('save')}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default Modal_AssetCreate_bankaccount;
