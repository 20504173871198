import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import translations from './config/translations.json';
import { getCookieValue } from './App';
import { fetchPageData } from './Util_API_calls'; // Adjust the path as necessary
import { t } from './Util_format';

function Page_Contactus({ onDataChange, ...props }) {
  const isMobile = useMediaQuery('(max-width:768px)'); // Mobile breakpoint

  React.useEffect(() => {
    const script = document.createElement('script');

    script.charset = 'utf-8';
    script.type = 'text/javascript';
    script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';

    script.onload = () => {
      if (window.hbspt) {
        window.hbspt.forms.create({
          region: "eu1",
          portalId: "25343058",
          formId: "297b08a6-ef50-4b03-bded-d0eeb25b121f",
        });
      }
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
      <iframe
        id="hs-form-iframe-0"
        className="hs-form-iframe"
        title="Contact Form"
        scrolling="no"
        style={{ position: 'static', border: 'none', display: 'block', overflow: 'hidden', width: '100%', height: '773px' }}
      />
  );
}

export default Page_Contactus;
