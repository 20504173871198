import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { AppBar, Button, Drawer, Icon, IconButton, InputAdornment, List, ListItem, ListItemIcon, ListItemText, Modal, TextField, Toolbar, Typography, createTheme, ThemeProvider, styled, Menu, MenuItem } from '@mui/material';
import { theme } from './theme';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import HelpIcon from '@mui/icons-material/Help';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AccountCircle from '@mui/icons-material/AccountCircle';
import SearchIcon from '@mui/icons-material/Search';
import TransferWithinAStationIcon from '@mui/icons-material/TransferWithinAStation';
import { getCookieValue, changeLanguage } from './App';
import wmlogo from './pics/logo_header.svg';
import flag_it from './pics/flag_it.svg';
import flag_en from './pics/flag_en.svg';
import useMediaQuery from '@mui/material/useMediaQuery';
import MenuIcon from '@mui/icons-material/Menu';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import MailIcon from '@mui/icons-material/Mail';
import { t } from './Util_format';
import Modal_ReadMessage from './Modal_ReadMessage';
import MailOutlineIcon from '@mui/icons-material/MailOutline'; // Icon for unread mail
import { markMessageAsRead } from './Util_API_calls'; // Adjust the path as necessary
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import CreditCardIcon from '@mui/icons-material/CreditCard';

function Structure_logged({ children, UserMessages }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [drawerReduced, setDrawerReduced] = useState(false);
  const [searchModalOpen, setSearchModalOpen] = useState(false);
  const handleSearchModalClose = () => setSearchModalOpen(false);
  const handleSearchModalOpen = () => setSearchModalOpen(true);
  const [open, setOpen] = useState(false);
  const [langMenuAnchorEl, setLangMenuAnchorEl] = useState(null);
  const [accountMenuAnchorEl, setAccountMenuAnchorEl] = useState(null);
  const currentFlag = getCookieValue('language') === 'en' ? flag_en : flag_it;
  const handleLanguageChange = (lang) => {
    handleClose(); // Close the menu
    changeLanguage(lang); // Change the language
  };

  const handleCloseMessageModal = () => {
    setIsMessagesModalOpen(false);
  };

  const unreadMessagesCount = UserMessages.filter(msg => !msg.is_read).length;

  const ListItemTextCickable = styled(ListItemText)(({ theme }) => ({
    '& .MuiListItemText-primary': {
      fontWeight: '500',
      color: '#34403A',
      fontSize: '0.875rem',
      fontFamily: 'Poppins',
      textOverflow: 'ellipsis',
    },
    '&.reduced-text .MuiListItemText-primary': { // When reduced
      fontSize: '0.7rem', // Smaller font
      textAlign: 'center', // Corrected here
    },
  }));

  const handleLangMenuClick = (event) => {
    setLangMenuAnchorEl(event.currentTarget);
  };

  const handleAccountMenuClick = (event) => {
    setAccountMenuAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setLangMenuAnchorEl(null);
    setAccountMenuAnchorEl(null);
  };

  const handleLogout = () => {
    navigate('/login?action=clean-cookie&reason=user_logout');
  };

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const ListItemIconStyled = styled(ListItemIcon)(({ theme }) => ({
    alignContent: 'center',  // Center the icon
    minWidth: drawerReduced ? 'unset' : undefined,  // Remove the default minWidth only when reduced
  }));


  const StyledListItem = styled(ListItem)(({ theme }) => ({
    cursor: 'pointer',
    borderRadius: '5px',
    marginBottom: '5px',
    transition: 'background-color 0.3s ease',
    flexDirection: drawerReduced ? 'column' : 'row',  // Vertical stack when reduced
    justifyContent: drawerReduced ? 'center' : 'flex-start',  // Centered when reduced
    alignItems: 'center',  // Horizontally center
    '&:hover': {
      backgroundColor: '#edefef',
    },
    '&:active': {
      backgroundColor: '#c5cacf',
    },
  }));

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const TitleListItem = styled(ListItem)(({ theme }) => ({
    pointerEvents: 'none',
    userSelect: 'none',
    fontSize: '0.8rem',
    paddingBottom: '10px',
  }));

  const isMobile = useMediaQuery('(max-width:768px)'); // Mobile breakpoint

  const contentStyles = isMobile
    ? {
      marginLeft: 0,
      width: 'calc(100vw - 40px)',
      paddingLeft: '20px',
      paddingRight: '20px',
      height: 'calc(100vh - 80px)',
      overflowY: 'auto',
      justifyContent: 'center !important',
      textAlign: 'center !important',
    }
    : {
      marginLeft: drawerReduced ? 100 : 260,
      width: drawerReduced ? 'calc(100vw - 140px)' : 'calc(100vw - 300px)',
      height: 'calc(100vh - 80px)',
      overflowY: 'auto', // fixed property name
      justifyContent: 'center',
      alignItems: 'center',
      color: '#FFFFFF',
      paddingTop: '0px',
      paddingLeft: '20px',
      paddingRight: '20px'
    };


  // if the cookie is not present, redirect to the login page
  useEffect(() => {
    const cookieRow = document.cookie.split('; ').find(row => row.startsWith('firebaseToken='));
    const cookieValue = cookieRow ? cookieRow.split('=')[1] : null;

    if (!cookieValue) {
      navigate('/login');
    } else {
      const loginOtpCompleted = getCookieValue('login_otp_completed');
      if (loginOtpCompleted !== 'true') {
        navigate("/login_otp");
      }
    }
  }, []);

  const [isMessagesModalOpen, setIsMessagesModalOpen] = useState(false);
  const [currentMessage, setCurrentMessage] = useState({ msg_id: null, subject: '', content: '' });

  // Function to open the modal
  const openMessagesModal = () => {
    setIsMessagesModalOpen(true);
  };

  // Function to close the modal
  const closeMessagesModal = () => {
    setIsMessagesModalOpen(false);
  };

  const handleReadMessage = (message) => {
    markMessageAsRead(message.msg_id);
    UserMessages.find(msg => msg.msg_id === message.msg_id).is_read = true;
    setCurrentMessage({
      msg_id: message.msg_id, // Assuming the message object has an 'id' field
      subject: message.subject,
      content: message.content
    });
    openMessagesModal(); // Open the modal
  };

  return (
    <div id="page_dashboard" className="page_dashboard" >
      <ThemeProvider theme={theme}>
        {isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={handleDrawerToggle}
            style={{
              position: 'absolute',
              backdropFilter: 'blur(10px)',
              top: '18px',
              left: '20px',
              zIndex: 1201
            }}
          >
            <MenuIcon />
          </IconButton>

        )}
        {!isMobile && (
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setDrawerReduced(!drawerReduced)}
            style={{
              position: 'absolute',
              top: '40px',
              transform: 'translateY(-50%) scale(0.5)',
              left: drawerReduced ? '90px' : '250px',
              border: '1px dotted #AAAAAA',
              borderRadius: '50%',
              width: '48px !important',
              height: '48px !important',
              zIndex: 1202,
              backgroundColor: '#FEFEFE',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {drawerReduced ?
              <ArrowForwardIcon style={{ fontSize: '24px' }} /> : <ArrowBackIcon style={{ fontSize: '24px' }} />
            }
          </IconButton>
        )}
        <AppBar position="static" style={{
          marginLeft: isMobile ? 0 : (drawerReduced ? 100 : 260),
          width: isMobile ? 'calc(100vw)' : (drawerReduced ? 'calc(100vw - 100px)' : 'calc(100vw - 260px)'),
          paddingLeft: isMobile ? 60 : (drawerReduced ? 0 : 0)
        }}>
          <Toolbar style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            height: '80px',
          }}
          >
            <IconButton onClick={handleSearchModalOpen}>
              <SearchIcon />
            </IconButton>

            <div style={{ position: 'relative', display: 'flex', alignItems: 'center' }}>
              {/* Language Menu */}
              <IconButton onClick={handleLangMenuClick}>
                <img src={currentFlag} alt="Italiano" style={{ width: '28px', height: '28px', borderRadius: '10px' }} />
              </IconButton>
              <Menu
                anchorEl={langMenuAnchorEl}
                keepMounted
                open={Boolean(langMenuAnchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: 8,
                    marginTop: '10px'
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                {[
                  { flag: flag_it, alt: "Italiano", lang: "it" },
                  { flag: flag_en, alt: "English", lang: "en" }
                ].map(item => (
                  <MenuItem
                    key={item.lang}
                    onClick={() => handleLanguageChange(item.lang)}
                    style={{
                      fontWeight: '500',
                      color: '#34403A',
                      fontSize: '0.875rem',
                      fontFamily: 'Poppins',
                      textOverflow: 'ellipsis'
                    }}
                  >
                    <img src={item.flag} alt={item.alt} style={{ width: '28px', height: '20px', marginRight: '10px' }} />
                    {item.alt}
                  </MenuItem>
                ))}
              </Menu>

              {/* Notifications */}
              <IconButton onClick={toggleDrawer(true)}>
                <Badge badgeContent={unreadMessagesCount} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
              <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
                <div
                  role="presentation"
                  onClick={toggleDrawer(false)}
                  onKeyDown={toggleDrawer(false)}
                >
                  <div style={{ padding: '20px' }}>
                    <Typography className='notifications_title'>
                      {t('notifications')}
                    </Typography>
                    {UserMessages.map((message, index) => {
                      // Log the entire message object
                      // console.log('Message:', message);

                      return (
                        <div className="notifications_list_with_border" key={index} onClick={() => handleReadMessage(message)} style={{ display: 'flex', alignItems: 'center' }}>
                          <Icon style={{ color: message.is_read ? '#f0f0f0' : '#2a9461', marginRight: '8px' }}>
                            {message.is_read ? <MailIcon /> : <MailOutlineIcon />}
                          </Icon>
                          {message.subject} e {message.is_read.toString()}
                        </div>
                      );
                    })}
                  </div>
                </div>
              </Drawer>
              {/* Account Menu */}
              <IconButton onClick={handleAccountMenuClick}>
                <AccountCircleIcon style={{ width: '28px', height: '28px', borderRadius: '5px' }} color="primary" />
              </IconButton>
              <Menu
                anchorEl={accountMenuAnchorEl}
                keepMounted
                open={Boolean(accountMenuAnchorEl)}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    borderRadius: 8,
                    marginTop: '10px'
                  },
                }}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <MenuItem onClick={handleClose} style={{
                  fontWeight: '500',
                  color: '#34403A',
                  fontSize: '0.875rem',
                  fontFamily: 'Poppins',
                  textOverflow: 'ellipsis',
                }}
                >
                  <HomeIcon color="primary" style={{ paddingRight: '10px' }} />
                  {t("home")}
                </MenuItem>
                <MenuItem onClick={handleClose} style={{
                  fontWeight: '500',
                  color: '#34403A',
                  fontSize: '0.875rem',
                  fontFamily: 'Poppins',
                  textOverflow: 'ellipsis'
                }}
                >
                  <AccountCircle color="primary" style={{ paddingRight: '10px' }} />
                  {t("profile")}
                </MenuItem>
                <MenuItem onClick={handleLogout}
                  style={{
                    fontWeight: '500',
                    color: '#34403A',
                    fontSize: '0.875rem',
                    fontFamily: 'Poppins',
                    textOverflow: 'ellipsis'
                  }}
                >
                  <LogoutIcon color="primary" style={{ paddingRight: '10px' }} />
                  {t("logout")}
                </MenuItem>
              </Menu>
            </div>
          </Toolbar>

        </AppBar>
        <div style={{ ...contentStyles }}>
          {children}
        </div>

        <Modal
          open={searchModalOpen}
          onClose={handleSearchModalClose}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)', position: 'absolute', backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
            <Typography variant="h6" id="simple-modal-title">
              Search
            </Typography>
            <TextField
              variant="outlined"
              placeholder="Search"
              fullWidth
              margin="normal"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
            <Button variant="contained" color="primary" style={{ marginTop: '20px' }} onClick={handleSearchModalClose}>
              Close
            </Button>
          </div>
        </Modal>

        <Drawer
          variant={isMobile ? "temporary" : "permanent"}
          open={isMobile ? open : true}
          onClose={handleDrawerToggle}
          className={drawerReduced ? "reduced-drawer" : ""}
          classes={{
            paper: drawerReduced ? "reduced-drawer" : "",
          }}
          style={{ padding: '5px' }}
        >
          <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>

              <div role="presentation" onClick={toggleDrawer} onKeyDown={toggleDrawer}>
                <List>
                  <ListItem
                    style={{
                      justifyContent: 'center',
                      paddingTop: '20px',
                      paddingBottom: '20px',
                      userSelect: 'none',
                      pointerEvents: 'none'
                    }}>
                    <img src={wmlogo} alt="Company Logo" style={{ width: '160px' }} />
                  </ListItem>

                  <TitleListItem>
                    {!drawerReduced && (
                      <ListItemText
                        primary="WorthMap"
                        primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#2a9461', fontSize: '11px' } }}
                      />
                    )}
                  </TitleListItem>
                  <StyledListItem
                    onClick={() => { navigate('/home'); }}
                    style={location.pathname.startsWith('/home') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><HomeIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("home")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("home")} />
                    )}
                  </StyledListItem>

                  <StyledListItem
                    onClick={() => { navigate('/profile'); }}
                    style={location.pathname.startsWith('/profile') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><PersonIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("profile")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("profile")} />
                    )}
                  </StyledListItem>


                  <TitleListItem>
                    {!drawerReduced && (
                      <ListItemText
                        primary={t('my_bank')}
                        primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#2a9461', fontSize: '11px' } }}
                      />
                    )}
                  </TitleListItem>

                  <StyledListItem
                    onClick={() => { navigate('/assets/all'); }}
                    style={location.pathname.startsWith('/assets') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><AccountBalanceIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("my_assets")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("my_assets")} />
                    )}
                  </StyledListItem>

                  <StyledListItem
                    onClick={() => { navigate('/liabilities/all'); }}
                    style={location.pathname.startsWith('/liabilities') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><CreditCardIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("my_liabilities")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("my_liabilities")} />
                    )}
                  </StyledListItem>

                  <StyledListItem
                    onClick={() => { navigate('/incomes/all'); }}
                    style={location.pathname.startsWith('/incomes') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><BusinessCenterIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("my_incomes")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("my_incomes")} />
                    )}
                  </StyledListItem>

                  <StyledListItem
                    onClick={() => { navigate('/expenses/all'); }}
                    style={location.pathname.startsWith('/expenses') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><ShoppingCartIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("my_expenses")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("my_expenses")} />
                    )}
                  </StyledListItem>


                  <StyledListItem
                    onClick={() => { navigate('/transactions'); }}
                    style={location.pathname.startsWith('/transactions') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><TransferWithinAStationIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("transactions")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("bank_transactions")} />
                    )}
                  </StyledListItem>

                  <TitleListItem>
                    {!drawerReduced && (
                      <ListItemText
                        primary={t('customer_support')}
                        primaryTypographyProps={{ style: { fontWeight: 'bold', color: '#2a9461', fontSize: '11px' } }}
                      />
                    )}
                  </TitleListItem>

                  <StyledListItem
                    component="a"
                    href="https://www.worthmap.com/kb"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ListItemIconStyled><HelpIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("user_guide")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("user_guide")} />
                    )}
                  </StyledListItem>

                  <StyledListItem
                    onClick={() => { navigate('/contact-us'); }}
                    style={location.pathname.startsWith('/contact-us') ? { backgroundColor: '#e0f0f9' } : {}}
                  >
                    <ListItemIconStyled><MailIcon color="primary" /></ListItemIconStyled>
                    {!drawerReduced ? (
                      <ListItemTextCickable primary={t("contact_us")} />
                    ) : (
                      <ListItemTextCickable className="reduced-text" primary={t("contact_us")} />
                    )}
                  </StyledListItem>


                </List>
              </div>
            </div>

          </div>
        </Drawer>
        {isMessagesModalOpen &&
          <Modal_ReadMessage
            onClose={closeMessagesModal}
            closeModal={handleCloseMessageModal}
            msg_id={currentMessage.msg_id}
            subject={currentMessage.subject}
            content={currentMessage.content}
          />
        }
      </ThemeProvider>        </div>
  );
}

export default Structure_logged;