import translations from './config/translations.json';
import { getCookieValue } from './App';

const italian_currency_formatter = new Intl.NumberFormat('it-IT', {
  style: 'currency',
  currency: 'EUR',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
});

export const number_to_italian_currency = (value) => {
  return italian_currency_formatter.format(value);
};

export const italian_currency_to_number = (amount) => {
  return parseFloat(amount.replace(/[^\d.,]/g, '').replace(/\./g, '').replace(/,/g, '.'));
}

export function t(key) {
  const language = getCookieValue('language') || 'it';
  // Check if the translations object has the key and language defined.
  if (translations[key] && translations[key][language]) {
    return translations[key][language];
  } else {
    // Return the key itself if translation is not found.
    return key;
  }
}