import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Typography, Table, TableBody, TableCell, TableHead, TableRow, TableSortLabel } from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import { t } from './Util_format';
import { cellTextStyle } from './Page_Assets';
import config from './config/env.json';

export function Table_assets_all({ URLassetType, assets, onPageChange, searchTerm, category, base_currency }) {
  const [sortConfig, setSortConfig] = useState({ key: 'user_asset_type', direction: 'asc' });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const navigate = useNavigate();

  const handleRedirectToAssetType = (assetType) => {
      navigate(`/assets/${assetType}`);
  }

  const requestSort = (key, type) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction, type });
  };

  const TableSort = (a, b, sortConfig) => {
    const { key, direction, type } = sortConfig;
    const resolvePath = (object, path, defaultValue) => {
      return path.split('.').reduce((o, p) => o ? o[p] : defaultValue, object);
    };
  
    let valA = resolvePath(a, key, null);
    let valB = resolvePath(b, key, null);
  
    // Apply translation if sorting by 'user_asset_type' and type is 'string'
    if (key === 'user_asset_type' && type === 'string') {
      valA = valA ? t('categories_assets_' + valA).toLowerCase() : '';
      valB = valB ? t('categories_assets_' + valB).toLowerCase() : '';
    } else {
      // Normalize values for sorting for other cases
      switch (type) {
        case 'currency':
          valA = valA ? parseCurrencyValue(valA) : 0;
          valB = valB ? parseCurrencyValue(valB) : 0;
          break;
        case 'number':
          valA = parseNumberValue(valA);
          valB = parseNumberValue(valB);
          break;
        case 'string':
          valA = valA ? valA.toLowerCase() : '';
          valB = valB ? valB.toLowerCase() : '';
          break;
      }
    }
  
    // Sort logic
    if (valA < valB) {
      return direction === 'asc' ? -1 : 1;
    }
    if (valA > valB) {
      return direction === 'asc' ? 1 : -1;
    }
    return 0;
  };
  
  
  

  function parseCurrencyValue(value) {
    const num = Number(value.replace(/[^0-9.-]+/g, ""));
    return isNaN(num) || !isFinite(num) ? Infinity : num;
  }

  function parseNumberValue(value) {
    const num = parseFloat(value);
    return isNaN(num) || !isFinite(num) ? Infinity : num;
  }


  const renderTableSort = (key, label, type) => (
    <TableSortLabel
      active={sortConfig.key === key}
      direction={sortConfig.key === key ? sortConfig.direction : 'asc'}
      onClick={() => requestSort(key, type)}
    >
      {t(label)}
    </TableSortLabel>
  );

  if (!category) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('please_select_the_asset_category')}
      </Typography>
    );
  } else if (!Array.isArray(assets) || assets.length === 0) {
    return (
      <Typography variant="body1" style={{ marginTop: '40px' }}>
        {t('no_assets_to_show')}
      </Typography>
    );
  }

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    // onPageChange(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const sortedAssets = [...assets].sort((a, b) => {
    return TableSort(a, b, sortConfig);
  });

  const filteredAssets = sortedAssets.filter(asset => {
    // prevent error switching between shares and bank accounts
    if (!asset.last_value) {
      return false;
    }

    if (!asset.user_asset_type) {
      return false;
    }

    const combinedFields = [
      asset.user_asset_type,
    ].filter(Boolean).join(' ').toLowerCase(); // Filter out any undefined or null values and join them into a single string

    asset.table_total_value = parseFloat(asset.last_value[base_currency]).toLocaleString('en-US', { style: 'currency', currency: base_currency });
    asset.table_PL_unit = parseFloat(asset.PL_unit[base_currency]).toLocaleString('en-US', { style: 'currency', currency: base_currency });

    // Find a category with a similar user_asset_type
    const category = config.ASSET_CATEGORIES.find(c =>
      c.user_asset_type.toLowerCase().includes(asset.user_asset_type.toLowerCase())
    );

    // Use the category title if available, or default to asset's user_asset_type
    const searchField = category ? t('categories_assets_' + category.user_asset_type) : asset.user_asset_type.toLowerCase();

    return searchField.includes(searchTerm.toLowerCase());
  });

  const displayedAssets = filteredAssets.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const evenRowStyle = {
    backgroundColor: '#F0F0F0',
  };

  const loss_color = {
    color: '#c0392b',
  };

  const gain_color = {
    color: '#2a9461',
  };

  return (
    <div>
      <Table style={{ marginTop: '20px' }}>
        <TableHead>
          <TableRow>
            <TableCell data-key='user_asset_type' data-type='string'>
              {renderTableSort('user_asset_type', t('category'), 'string')}
            </TableCell>
            <TableCell data-key='table_total_value' data-type='currency' style={{ textAlign: 'right', width: '150px' }}>
              {renderTableSort('table_total_value', t('total_value'), 'currency')}
            </TableCell>
            <TableCell data-key='table_PL_unit' data-type='currency' style={{ textAlign: 'right', width: '150px' }}>
              {renderTableSort('table_PL_unit', t('total_PL'), 'currency')}
            </TableCell>
            {/* Add additional headers as needed */}
          </TableRow>
        </TableHead>

        <TableBody>
          {displayedAssets.map((asset) => (
            <TableRow key={asset.user_asset_id} onClick={() => handleRedirectToAssetType(asset.user_asset_type)} style={{ cursor: 'pointer' }}>
              <TableCell style={{ textAlign: 'left', ...cellTextStyle }}>{t('categories_assets_' + config.ASSET_CATEGORIES.find(c => c.user_asset_type === asset.user_asset_type).user_asset_type)}</TableCell>
              <TableCell style={{ textAlign: 'left', ...cellTextStyle, paddingRight: '40px', textAlign: 'right', width: '150px' }}
              >
                {asset.table_total_value}
              </TableCell>
              <TableCell style={{ textAlign: 'left', ...cellTextStyle, paddingRight: '40px', textAlign: 'right', width: '150px' }}>
                {config.ASSET_CATEGORIES.find(c => c.user_asset_type === asset.user_asset_type).calculate_value_change_PL === true && (
                  asset.table_PL_unit
                ) || (
                    asset.table_PL_unit
                  )
                }
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        style={{ marginTop: '20px' }}
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={filteredAssets.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        className='table_bottom'
      />
    </div>
  );
}
